import { Box, Button, CircularProgress, Grid, TextField } from '@material-ui/core'
import React, { Component } from 'react'
import { Autocomplete } from '@material-ui/lab';
import Service from '../../config/networkutils';
import { getCookie } from '../../utils/CookieHelper';
import { withSnackbar } from 'notistack';
import MaterialTable from 'material-table';
import moment from 'moment';

const token = getCookie("ptd_ts_token");
class CheckAvailability extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            allRoles: [],
            selectedRole: this.props.role ? this.props.role : {},
            startDate: this.props.startDate ? this.props.startDate : moment().format('YYYY-MM-DD'),
            endDate: this.props.endDate ? this.props.endDate : moment().add(6, 'month').format('YYYY-MM-DD'),
            qty: this.props.qty ? this.props.qty : 0,
            isLoading: false,
            submitDisabled: false,
            tableLoading: false,
            showTable: false,
            columns: []
        }
    }



    getRoles = () => {
        Service.get(`/api/roles/get_default_data/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ allRoles: res.data.roles }, () => {
                    this.setState({ isLoading: false })
                })

            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                this.setState({ buttonDisabled: false })
            });
    }

    componentDidMount() {
        if (this.props.role && this.props.qty && this.props.startDate && this.props.endDate) {
            // this.setState({ isLoading: true })
            this.getEmployees(this.props.startDate, this.props.endDate, this.props.role, this.props.qty)
        } else if (!this.props.role) {
            this.setState({ isLoading: true })
            this.getRoles()
        } else {
            this.setState({ isLoading: false })
        }
    }

    getEmployees = (startDate, endDate, role, qty) => {
        if (!startDate || !endDate || !role || !qty) {
            this.props.enqueueSnackbar('Please fill all the fields!', {
                variant: 'warning'
            });

        } else {
            this.setState({ tableLoading: true, submitDisabled: true })
            Service.get(`/api/ptd/employees/resource_available_for_role/?role_id=${role.id}&start=${startDate}&end=${endDate}&required_qty=${qty}`, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    var columns = []
                    columns.push(
                        {
                            title: 'Emp No', field: 'emp_id', width: '6%',
                        },
                        {
                            title: 'Name', field: 'first_name', width: '6%',
                            render: rowData => <Box color={rowData.is_conflict ? '#ff9900' : null}>{rowData.first_name + ' ' + rowData.last_name}</Box>,
                        },
                        {
                            title: 'Role', field: 'role_name', width: '6%',
                        })
                    res.data.all_mondays_date.sort((a, b) => moment(a).valueOf() - moment(b).valueOf()).forEach(element => {
                        if (element) {
                            columns.push({
                                title: moment(element).format('DD MMM YYYY'), field: element, 
                                render: rowData => (rowData.week_data[element] || rowData.week_data[element] === 0) ? <Box color={parseFloat(rowData.week_data[element].toFixed(2)) > this.state.qty ? '#ff0000' : '' }>{parseFloat(rowData.week_data[element].toFixed(2))}</Box> : '',
                            })
                        }
                    });
                    this.setState({ data: res.data, showTable: true, columns }, () => {
                        this.setState({ tableLoading: false, isLoading: false, submitDisabled: false })
                    })
                })
                .catch(error => {
                    console.log(error)
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                    this.setState({ submitDisabled: false, tableLoading: false, showTable: false })
                });
        }
    }

    render() {
        return (
            <div>
                {this.state.isLoading ? <Box display="flex" justifyContent="center" alignItems="center" width="100%" style={{ marginBottom: '30px' }}><CircularProgress color='primary' /></Box> :
                    <Box marginTop={2} display={'flex'} flexDirection={'column'}>
                        {this.props.startDate && this.props.endDate && this.props.role && this.props.qty ? null :
                            <Box>
                                <Grid container justifyContent="center" spacing={1} style={{ marginBottom: '20px' }}>
                                    {this.props.startDate ? null :
                                        <Grid item xs={3}>
                                            <TextField
                                                id="start"
                                                label="Start Date"
                                                variant='outlined'
                                                type="date"
                                                value={this.state.startDate}
                                                onChange={(e) => this.setState({ startDate: e.target.value })}
                                                size="small"
                                                style={{ width: '100%' }}
                                                defaultValue="2017-05-24"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>}
                                    {this.props.endDate ? null :
                                        <Grid item xs={3}>
                                            <TextField
                                                id="end"
                                                label="End Date"
                                                type="date"
                                                value={this.state.endDate}
                                                onChange={(e) => this.setState({ endDate: e.target.value })}
                                                variant='outlined'
                                                size="small"
                                                defaultValue="2017-05-24"
                                                style={{ width: '100%' }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>}
                                    {this.props.role ? null :
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                id="role_id"
                                                options={this.state.allRoles}
                                                disableCloseOnSelect
                                                getOptionLabel={(option) => option.job_roles}
                                                onChange={(e, value) => this.setState({ selectedRole: value })}
                                                size='small'
                                                value={this.state.selectedRole}
                                                renderInput={(params) => (
                                                    <TextField {...params} size='small' variant="outlined" label="Role" placeholder="Role" />
                                                )}
                                            />
                                        </Grid>}
                                    {this.props.qty ? null :
                                        <Grid item xs={2}>
                                            <TextField
                                                size='small'
                                                variant="outlined"
                                                id="required_qty"
                                                label="Qty"
                                                placeholder="Qty"
                                                type='number'
                                                style={{ width: '100%' }}
                                                value={this.state.qty}
                                                onChange={(e) => this.setState({ qty: e.target.value })}
                                            />
                                        </Grid>}
                                    {this.props.startDate && this.props.endDate && this.props.role && this.props.qty ? null :
                                        <Grid item xs={1}>
                                            <Button disabled={this.state.submitDisabled} color='primary' variant='contained' onClick={() => this.getEmployees(this.state.startDate, this.state.endDate, this.state.selectedRole, this.state.qty)} >Submit</Button>
                                        </Grid>}
                                </Grid>
                            </Box>}
                        {
                            this.state.showTable ?
                                <Box marginTop={2}>
                                    <MaterialTable
                                        data={this.state.data.available_emp}
                                        isLoading={this.state.tableLoading}
                                        title="Available Employees"
                                        columns={this.state.columns}
                                        options={
                                            {
                                                search: true,
                                                paging: false,
                                                // filtering: true,
                                                sorting: true,
                                                padding: 'dense',
                                                fixedColumns: {
                                                    left: 3
                                                },
                                            }
                                        }
                                        onRowClick={(event, rowData) => { this.props.selectedUser(event, rowData) }}
                                    />
                                </Box> : null}
                    </Box>
                }
            </div>
        )
    }
}

export default withSnackbar(CheckAvailability)
