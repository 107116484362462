import { Box, CircularProgress, Paper, Typography } from '@material-ui/core';
import React, { Component } from 'react'
import { getPrimaryColor, getSecondaryColor } from '../../Helper/CommonThings';
import Service from '../../../config/networkutils';
import { getCookie } from '../../../utils/CookieHelper';
import moment from 'moment';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import GraphComponent from '../../GraphComponent/GraphComponent';
import BarGraphComponent from '../../BarGraphComponent/BarGraphComponent';
import { Scrollbar } from 'react-scrollbars-custom';
import { withSnackbar } from 'notistack';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
 
const token = getCookie("ptd_ts_token");
// const lastSelectedProject = getCookie('lastSelectedProject')
 
class ProjectDetails extends Component {
 
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            value: '1',
            showTab: false,
            data: [],
            startDate: moment().subtract(1, "month").format("YYYY-MM-DD"),
            endDate: moment().add(5, "years").format("YYYY-MM-DD"),
            projectName: null,
            graphLoading: false,
            lazyLoading: false,
            copyData: [],
            dvscDisabled: false,
            attritionDisabled: false
        }
        this.handleLoading = this.handleLoading.bind(this)
    }
    screenSizeCheck() {
        if (window.innerWidth > 1600) {
            return true
        } else {
            return false
        }
    }
 
    // componentDidMount() {
    //     if (localStorage.getItem('lastSelectedProject')) {
    //         this.getData(JSON.parse(localStorage.getItem('lastSelectedProject')))
    //     }
    // }
 
    getData(project) {
        this.setState({
            loading: true,
            showTab: true,
            copyData: [],
            data: []
        }, () => {
 
            Service.get(`/api/roles/dvsc_report/?all_role_group_data=${1}&project=${project.id}&start=${moment().subtract(1, "month").format("YYYY-MM-DD")}&end=${moment().add(this.screenSizeCheck() ? 5 : 3, "years").format("YYYY-MM-DD")}`, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    this.setState({
                        projectName: project.name
                    })
                    // this.setState({
                    //     data: res.data,
                    //     projectName: project.name
                    // }, () => {
                    //     this.setState({
                    //         loading: false,
                    //         disabled: false,
                    //         attritionDisabled: false,
                    //         dvscDisabled: false
                    //     })
                    // })
                    this.setState({
                        data: res.data
                    }, () => {
                        this.setState({
                            loading: false
                        })
                    })
 
 
                }).catch(error => {
                    console.log(error)
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                })
        })
 
    }
 
    handleChange = (event, newValue) => {
        this.setState({ value: newValue })
        //     () => {
        //     if(newValue === '1'){
        //         this.setState({
        //             attritionDisabled: true
        //         })
        //     }else{
        //         this.setState({
        //             dvscDisabled: true
        //         })
        //     }
        //     this.setState({
        //         data:[]
        //     },() =>{
        //     this.setState({  loading: true })
        //     this.lazyLoading(this.state.copyData)
 
        //     })
        //     this.setState({ projectLoading: false, loading: false })
        // })
    };
 
    handleLoading = (childData) => {
        this.setState({
            loading: false,
            projectLoading: false
        })
    }
 
    // lazyLoading = (data) =>{
    //     if(data.length > 0){
    //         var index = 0
    //         var stepIndex = 0
    //         var dataArray = []
    //         this.timer = setInterval(() => {
 
    //             for (index = 0; index < stepIndex; index++) {
    //                 const element = data[index];
    //                 dataArray.push(element)
 
    //             }
    //             this.setState({ data: dataArray },() =>{
    //                 dataArray=[]
    //                 if(index === data.length){
    //                     clearInterval(this.timer)
    //                     this.setState({
    //                         lazyLoading: false
    //                     },() =>{
    //                         this.setState({
    //                         attritionDisabled: false,
    //                         dvscDisabled: false
    //                         })
    //                     })
    //                 }else{
    //                 this.setState({
    //                     loading: false,
    //                     disabled: false
    //                 },() =>{
    //                     this.setState({
    //                         lazyLoading: true
    //                     })
    //                 })
    //             }
    //             });
    //             stepIndex += (data.length - stepIndex) < stepIndex+5 ? data.length - stepIndex : 5
    //             index = stepIndex
    //         }, 300)
    //     }else{
    //         this.setState({
    //             data: []
    //         },() =>{
    //             this.setState({
    //                 loading: false,
    //                 disabled: false,
    //             })
    //         })
    //     }
    // }
 
 
 
 
    render() {
        return (
            <div>
                <Paper style={{ height: window.innerHeight - 110, borderRadius: '10px', border: "2px solid " + getPrimaryColor(), color: getSecondaryColor() }}>
                    {localStorage.getItem('lastSelectedProject') === null ?
                        <Box display={'flex'} fontSize={this.screenSizeCheck() ? '16px' : '11px'} justifyContent={'center'} alignItems={'center'} height={window.innerHeight - 120}>
                            Please select a Project to view the details</Box>
                        :
                        <div>
                            {this.state.loading ?
                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <CircularProgress />
                                </Box>
                                :
                                <div>
                                    <Box padding={1} fontSize={'16px'}>
                                        {localStorage.getItem('lastSelectedProject') === null ?
                                            <Box display={'flex'} fontSize={this.screenSizeCheck() ? '16px' : '11px'} justifyContent={'center'} alignItems={'center'} height={window.innerHeight - 120}>
                                                Please select a Project to view the details</Box>
                                            :
                                            this.state.projectName}
                                    </Box>
                                    {/* {this.state.data ? */}
                                    <div style={{
                                        padding: '2px'
                                    }} >
                                        {this.state.data.length > 0 ?
                                            <div>
                                                <Box display='flex' flexDirection='row'>
                                                    <ToggleButtonGroup
                                                        value={this.state.value}
                                                        exclusive
                                                        size="small"
                                                        onChange={this.handleChange}
                                                    >
                                                        <ToggleButton disabled={this.state.dvscDisabled} value="1" style={{ fontSize: this.screenSizeCheck() ? '13px' : '9px' }}>
                                                            D vs C Graph
                                                        </ToggleButton>
                                                        <ToggleButton disabled={this.state.attritionDisabled} value="2" style={{ fontSize: this.screenSizeCheck() ? '13px' : '9px' }}>
                                                            Attrition Graph
                                                        </ToggleButton>
                                                    </ToggleButtonGroup>
                                                    <Box display='flex' flexDirection='row' marginLeft={1} marginTop={1.5}>
                                                        <Box display='flex' flexDirection='center' justifyContent='center'>
                                                            <FiberManualRecordIcon style={{ color: "#febc3b" }} />
                                                            <Typography style={{ color: "#000", fontSize: this.screenSizeCheck() ? '13px' : '10px' }} >
                                                                {this.state.value === '1' ? 'Other Demand' : 'Attrition@10%'}</Typography></Box>
                                                        <Box display='flex' flexDirection='center' justifyContent='center'>
                                                            <FiberManualRecordIcon style={{ color: "#25e6a5" }} />
                                                            <Typography style={{ color: "#000", fontSize: this.screenSizeCheck() ? '13px' : '10px' }}>{this.state.value === '1' ? 'Capacity' : 'Growth Needed'}</Typography></Box>
                                                        <Box display='flex' flexDirection='center' justifyContent='center'>
                                                            <FiberManualRecordIcon style={{ color: "#2da3fc" }} />
                                                            <Typography style={{ color: "#000", fontSize: this.screenSizeCheck() ? '13px' : '10px' }} >{this.state.value === '1' ? 'Demand' : 'Capacity'}</Typography></Box>
                                                    </Box>
                                                </Box>
                                                < Box marginTop={'1px'} padding={'1px'} width={'100%'}>
                                                    <Scrollbar style={{ width: '100%', height: window.innerHeight - 210 }}>
                                                        {this.state.value === '1' ?
                                                            <div style={{ height: window.innerHeight - 110 }}>
                                                                {this.state.data.map((rowData, i) => {
                                                                    return (
                                                                        <Box>
                                                                            <Typography>{rowData.name}</Typography>
                                                                            <GraphComponent rowData={rowData} dashboard={true} loading={this.handleLoading} />
                                                                        </Box>)
                                                                })}
                                                                <div>
                                                                    {this.state.lazyLoading ? <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                                        <CircularProgress />
                                                                    </Box> : null}
                                                                </div>
 
                                                            </div>
                                                            :
                                                            <div style={{ height: window.innerHeight - 110 }} >
                                                                {this.state.data.map((rowData, i) => (
                                                                    <Box>
                                                                        <Typography>{rowData.name}</Typography>
                                                                        <BarGraphComponent rowData={rowData} dashboard={true} loading={this.handleLoading} />
                                                                    </Box>
                                                                ))}
                                                                <div>
                                                                    {this.state.lazyLoading ? <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                                        <CircularProgress />
                                                                    </Box> : null}
                                                                </div>
                                                            </div>
                                                        }
                                                    </Scrollbar>
                                                </Box>
                                            </div>
                                            :
                                            <Box display={'flex'} fontSize={this.screenSizeCheck() ? '16px' : '13px'} justifyContent={'center'} alignItems={'center'}
                                                style={{ position: 'absolute', left: '85%', top: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }} >
                                                {this.state.data.length === 0 ? 'No Data Available for this Project' : <CircularProgress />}
                                            </Box>
                                        }
 
                                    </div>
                                    {/* // }</div> */}
 
                                    {/* // : null} */}
                                </div>}
                        </div>}
                </Paper >
 
            </div >
        )
    }
}
export default withSnackbar(ProjectDetails);