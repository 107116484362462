import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TransferList from './TransferList';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function Accordions(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleToUpdate = (stage, left, right) => {
    props.value[stage].left = left;
    props.value[stage].right = right;
    props.handleToUpdate(props.value, props.resData, props.data, props.user)
  }


  return (
    <div className={classes.root}>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>Basic Access</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TransferList value={props.value.l0a} stage={'l0a'} handleToUpdate={handleToUpdate} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.heading}>CRM Table</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TransferList value={props.value.l1a} stage={'l1a'} handleToUpdate={handleToUpdate} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography className={classes.heading}>CRM Input Tables</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TransferList value={props.value.l2a} stage={'l2a'} handleToUpdate = {handleToUpdate} />
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel13bh-content"
          id="panel13bh-header"
        >
          <Typography className={classes.heading}>Roles</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TransferList value={props.value.l3a} stage={'l3a'} handleToUpdate = {handleToUpdate} />
        </AccordionDetails>
      </Accordion>

       <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography className={classes.heading}>Employees</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TransferList value={props.value.l4a} stage={'l4a'} handleToUpdate = {handleToUpdate}  />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <Typography className={classes.heading}>Unsecured Opportunity</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TransferList value={props.value.l5a} stage={'l5a'} handleToUpdate = {handleToUpdate}  />
        </AccordionDetails>
      </Accordion>
       <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
        >
          <Typography className={classes.heading}>Secured Opportunity</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TransferList value={props.value.l6a} stage={'l6a'} handleToUpdate = {handleToUpdate} />
        </AccordionDetails>
      </Accordion>
     <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel7bh-content"
          id="panel7bh-header"
        >
          <Typography className={classes.heading}>Reports</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TransferList value={props.value.l7a} stage={'l7a'} handleToUpdate = {handleToUpdate} />
        </AccordionDetails>
      </Accordion>
      {/* <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel8bh-content"
          id="panel8bh-header"
        >
          <Typography className={classes.heading}>Users & Access</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TransferList value={props.value.l8a} stage={'l8a'} handleToUpdate = {handleToUpdate} />
        </AccordionDetails>
      </Accordion> */}
      <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel9bh-content"
          id="panel9bh-header"
        >
          <Typography className={classes.heading}>Combined Resource Profile</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TransferList value={props.value.l9a} stage={'l9a'} handleToUpdate = {handleToUpdate} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
