import { AppBar, Box, Dialog, DialogContent, DialogTitle, Divider, IconButton, ListItem, Popover, Toolbar, Typography } from '@material-ui/core'
import React, { Component } from 'react'
import { getPrimaryColor, getSecondaryColor } from '../../Helper/CommonThings';
import CloseIcon from '@material-ui/icons/Close';
import ROAPReportHome from '../../Reports/ROAPReport/ROAPReportHome';
import ResourceLocation from '../../Reports/ResourceLocation/ResourceLocation';
import OHLHomeClass from '../../Reports/OHL/OHLHomeClass';
import DvsCReports from '../../DvsCReports.js/DvsCReports';
import { withSnackbar } from 'notistack';
import { getCookie } from '../../../utils/CookieHelper';
import Service from '../../../config/networkutils';
import { CoffeeLoading } from 'react-loadingg';
import StandBy from '../../Reports/StandyBy/StandBy';

const token = getCookie("ptd_ts_token")

const reports = ["Demand vs Capacity", "ROAP", "Staff Location", "Other Reports"]

// const ohlroles = [
//     58, - General Foreman
//     65, - Head of Operations
//     171, - Apprentice General Foreman (a)
//     124, - Senior General Foreman
//     45, - Driver 1
//     138, - Storeperson
//     203, - Foreman
//     196, - General Operatives
//     204, - Foreman 2 (a)
//     205, - LE1
//     206, - LE2
//     207, - Leading Hand
//     208, - Apprentice Over Head Line Technician
//     209, - Overhead Lines Trainee
//     210, - SPO
//     270, - Driver 2
// ]

// const civilsroles = [
//19, - FFM (Civils)  
//20, - Foreman (Civils) (a)
//218, - GO (Civils)
//219, - TM1 
//220, - TM2 
//221, - TM3 
//271 -  General Foreman (Civils)
//]

// var gfRoleId = [58, 124]
// var gfRoleIdCivils = [271]

const component = {
    0: <DvsCReports />,
    1: <ROAPReportHome />,
    2: <ResourceLocation />,
    // 3: <OHLHomeClass roles={ohlroles} gfRoleId={gfRoleId} discipline="ohl" />,
    // 4: <OHLHomeClass roles={civilsroles} gfRoleId={gfRoleIdCivils} discipline="civils" />
}

class Reports extends Component {
    state = {
        open: false,
        title: '',
        index: 0,
        gangOpen: false,
        discipline: '',
        roles: [],
        gfRoleId: [],
        data: [],
        anchorEl: null,
        coffeeLoading: false,
        otherReportsDialog: false
    }

    componentDidMount() {
        this.fetchGangStructures();
    }

    fetchGangStructures = () => {
        Service.get(`/api/gang/report/roles/get_category_with_roles/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                if (!reports.includes("Gang Structures")) {
                    reports.push("Gang Structures")
                }
                this.setState({ data: res.data });
            })
            .catch(error => {
                console.log(error);
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    handleClose = () => {
        this.setState({ open: false })
    }

    openComponent = (e, title, index) => {
        if (title === "Gang Structures") {
            if (this.state.data.length > 0) {
                this.setState({
                    anchorEl: e.currentTarget
                })
            } else {
                this.props.enqueueSnackbar('No Gang Structures found!', {
                    variant: 'warning'
                });
            }
        } else if (title === "Other Reports") {
            this.setState({ otherReportsDialog: true })
        } else {
            this.setState({
                open: true,
                title: title,
                index: index
            })
        }
    }

    handleGangClose = () => {
        this.setState({ gangOpen: false })
    }

    openGang = (e, item) => {
        this.setState({
            anchorEl: null,
            coffeeLoading: true
        }, () => {

            this.setState({
                roles: item.level_1,
                gfRoleId: item.level_0,
                discipline: item.key,
                gangOpen: true,
                title: item.name,
                coffeeLoading: false
            })
        })
    }

    render() {

        const open = Boolean(this.state.anchorEl);

        const handleAnchorClose = () => {
            this.setState({ anchorEl: null })
        };

        const handleOtherReportsDialogClose = () => {
            this.setState({ otherReportsDialog: false })
        }

        return (
            <div>
                <div>
                    {reports.map((item, index) => {
                        return (
                            <div>
                                <ListItem
                                    style={{ padding: "10px" }}
                                    onClick={(e) => this.openComponent(e, item, index)}
                                    button>
                                    {item}
                                </ListItem>
                                <div>
                                    {index !== reports.length - 1 ?
                                        <Divider variant="middle" style={{ backgroundColor: getSecondaryColor() }} /> : null}
                                </div>
                                <Popover
                                    // id={id}
                                    open={open}
                                    anchorEl={this.state.anchorEl}
                                    onClose={handleAnchorClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >
                                    {this.state.data.map((item, index) => {
                                        return (
                                            <div style={{ width: 300 }}>
                                                <ListItem
                                                    key={index}
                                                    style={{ padding: "10px" }}
                                                    onClick={(e) => this.openGang(e, item)}
                                                    button>
                                                    {item.name}
                                                </ListItem>
                                                <Divider style={{ backgroundColor: getSecondaryColor() }} />
                                            </div>
                                        )
                                    })}
                                </Popover>
                            </div>
                        )
                    })
                    }
                </div >

                <Dialog fullScreen open={this.state.open} onClose={this.handleClose}>
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6">
                                {this.state.title}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Box margin={2} marginTop={10}>
                        {component[this.state.index]}
                    </Box>
                </Dialog>

                <Dialog fullScreen open={this.state.gangOpen} onClose={this.handleGangClose}>
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.handleGangClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6">
                                {this.state.title}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Box margin={2} marginTop={10}>
                        <OHLHomeClass roles={this.state.roles} gfRoleId={this.state.gfRoleId} discipline={this.state.discipline} />
                    </Box>
                </Dialog>


                <Dialog fullWidth={true} maxWidth='sm' open={this.state.otherReportsDialog} >
                    <DialogTitle >
                        <Box display={'flex'} flexDirection={'row'} width={'100%'} alignItems={'center'}>
                            <Box flexGrow={1}>
                                <Typography variant="h5" >
                                    Other Reports
                                </Typography>
                            </Box>
                            <IconButton onClick={handleOtherReportsDialogClose} color='error'>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </DialogTitle>
                    <DialogContent >
                        <StandBy />
                    </DialogContent>
                </Dialog>

                <Dialog open={this.state.coffeeLoading} maxWidth={"md"}>
                    <DialogContent style={{ padding: "70px" }}>
                        <CoffeeLoading color={getPrimaryColor()} />
                    </DialogContent>
                </Dialog>
            </div >
        )
    }
}
export default withSnackbar(Reports);