import React, { Component } from 'react';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import OHLStaff from './OHLStaff/OHLStaff';
import GFs from './Resource/GFs';
import OHLDashboard from './Dashboard/OHLDashboard';
import { getPrimaryColor } from '../../Helper/CommonThings';
import ReorderIcon from '@material-ui/icons/Reorder';
import Drawer from '@material-ui/core/Drawer';
import OHLStaffTables from './OHLStaff/OHLStaffTables'
import GfsTable from './Resource/GfsTable';
import { IconButton } from '@material-ui/core';
import { Tabs } from '@mui/material';
import Service from '../../../config/networkutils';
import { getActiveRoles, getAllElectricityOHLTcRMs, getEmployees, getGeneralforeman, getGeneralforemanGang, getOwnerDelivery, getRegion, getSubSector } from '../../../config/PromisesHandler';
import { LoopCircleLoading } from 'react-loadingg';
import moment from 'moment';
import { getCookie } from '../../../utils/CookieHelper';

const token = getCookie("ptd_ts_token");

class OHLHomeClass extends Component {

    constructor(props) {
        super(props)
        this.state = {
            value: 0,
            open: true,
            rowData: {},
            resourceRowData: {},
            loading: true,
            staffLoading: true,
            resourceLoading: true,
            option: null,
            name: null,
            gfs: [],
            gfsCopy: [],
            ohlEempObj: {},
            gfLoading: true,
            ohlLoadin: true
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleData = this.handleData.bind(this)
        this.handleResourceData = this.handleResourceData.bind(this)
        this.handleOption = this.handleOption.bind(this)
        this.handleName = this.handleName.bind(this)
        this.updateParent = this.updateParent.bind(this)
    }

    handleChange(event, newValue) {
        if (newValue === 0) {
            // setResourceLoading(false)
            this.setState({
                resourceLoading: false
            })
        } else {
            // setLoading(false)
            this.setState({
                loading: false
            })
        }
        if (newValue === 2) {
            // setOpen(false)
            this.setState({
                open: false
            })
        }
        // setValue(newValue);
        this.setState({
            value: newValue
        })
    };

    handleClose() {
        // setOpen(false)
        this.setState({
            open: false
        })
    }

    handleData(childData) {
        // setRowData(childData)
        // setLoading(false)
        // setOpen(false)
        this.setState({
            ohlLoadin: true,
            rowData: childData,
            // loading: false,
            open: false
        }, () => {
            this.setState({
                ohlLoadin: false
            })
        })

    }

    handleResourceData(childData) {
        // setResourceRowData(childData)
        // setResourceLoading(false)
        // setOpen(false)
        this.setState({
            gfLoading: true,
            resourceRowData: childData,
            open: false
        }, () => {
            this.setState({
                // resourceRowData: true,
                gfLoading: false,

            })
        })
    }

    handleOption(childData) {
        // setOption(childData)
        this.setState({
            option: childData
        }, () => {
            this.setState({
                resourceLoading: false
            })
        })
    }

    handleName(childData) {
        // setName(childData)
        this.setState({
            name: childData
        })
    }

    getEmpFomattedLookupObj(arr) {
        let obj = {}
        arr.forEach(ele => {
            obj = { ...obj, ...{ [ele.id]: `${ele.first_name} ${ele.last_name}` } }
        })
        return obj
    }

    getFomattedLookupObj(arr) {
        let obj = {}
        arr.forEach(ele => {
            obj = { ...obj, ...{ [ele.id]: ele.name } }
        })
        return obj
    }

    getGeneralforemanGangNew = () => {
        return new Promise(
            (resolve, reject) => {
                Service.get('/api/ohl/generalforeman/', {
                    headers: {
                        Authorization: "Token " + token,
                    },
                }).then(res => resolve(res.data)).catch(error => reject(error))
            }
        )
    }

    ohlForStaff() {
        this.ohlroles = this.props.roles
        this.gfRoleId = this.props.gfRoleId

        Promise.all([
            getEmployees(),
            getGeneralforeman(this.props.discipline),
            getActiveRoles(),
             getSubSector(),
            getRegion(),
            getOwnerDelivery(),
            getAllElectricityOHLTcRMs(),
            getGeneralforemanGang(),
            this.getGeneralforemanGangNew()])
            .then(res => {
                let ohlemps = []
                let allOHLEmps = []
                // Filtering all the OHL Role employees mentioned in the this.ohlroles
                for (let empin = 0; empin < res[0].length; empin++) {
                    this.ohlroles.forEach(roleId => {
                        if (res[0][empin].job_title === roleId) {
                            ohlemps.push(res[0][empin])
                            allOHLEmps.push(res[0][empin])
                        }
                    })
                }

                // filtering only generalforemen role
                let allGfs = ohlemps.filter(emp => this.gfRoleId.findIndex(role => role === emp.job_title) !== -1)
                allGfs = allGfs.filter(emp => !emp.end_date || moment(emp.end_date).isAfter(moment().format()))
                let allGangEmps = ohlemps.filter(emp => emp.job_title !== this.gfRoleId)

                let filteredGFEmps = allOHLEmps.filter(emp => !emp.end_date || moment(emp.end_date).isAfter(moment().format()))
                filteredGFEmps = filteredGFEmps.filter(emp => this.gfRoleId.findIndex(role => role === emp.job_title) !== -1)

                // filtering not Allocated generalforemen
                let unAllocatedGF = [...filteredGFEmps];
                for (let gfEmpIndex = 0; gfEmpIndex < res[8].length; gfEmpIndex++) {
                    let filteredGfNotAllocatedEmps = unAllocatedGF.filter(emp => emp.id !== res[8][gfEmpIndex].ptd_employee)
                    unAllocatedGF = filteredGfNotAllocatedEmps
                }
                // if (unAllocatedGF.length === 0) {

                //     unAllocatedGF = [...allGfs];
                // }

                let rolesObj = {}
                res[2].forEach(ele => {

                    let newData = { [ele.id]: ele.job_roles }
                    rolesObj = { ...rolesObj, ...newData }
                })
                // getting lookup objects
                let ohlEempObj = this.getEmpFomattedLookupObj(ohlemps)
                let sec = this.getFomattedLookupObj(res[3])
                let reg = this.getFomattedLookupObj(res[4])
                let odu = this.getFomattedLookupObj(res[5])
                let live = []

                for (let index = 0; index < res[6].length; index++) {
                    const element = res[6][index];
                    if (element.archive_reason === 0) {
                        live.push(element)
                    }

                }


                this.setState({
                    allGfs,
                    allOHLEmps,
                    ohlEempObj,
                    unAllocatedGF,
                    allGangEmps,
                    gfs: res[1],
                    gfsCopy: res[1],
                    rolesObj,
                    sector: sec,
                    owner_region: reg,
                    owner_delivery_unit: odu,
                    allElectricityOHLTcRMs: live,
                    allGangmen: res[7]
                }, () => this.setState({ loading: false, resourceLoading: false }))
            })
            .catch(e => {
                this.setState({ loading: false, resourceLoading: false })
            })
    }

    updateParent(gfs, gfsCopy, allGfs, unAllocatedGF) {
        this.setState({ gfs, gfsCopy, allGfs, unAllocatedGF })
    }

    componentDidMount() {
        this.setState({
            loading: true,
            resourceLoading: true
        })
        this.ohlForStaff()
    }

    // ohlForStaff() {
    //     this.ohlroles = this.props.roles
    //     this.gfRoleId = this.props.gfRoleId

    //     Promise.all([
    //         getEmployees(),
    //         getGeneralforeman(this.props.discipline),
    //         getActiveRoles(),
    //         getSubSector(),
    //         getRegion(),
    //         getOwnerDelivery(),
    //         getAllElectricityOHLTcRMs(),
    //         getGeneralforemanGang(),
    //         this.getGeneralforemanGangNew()])
    //         .then(res => {
    //             let ohlemps = []
    //             let allOHLEmps = []
    //             // Filtering all the OHL Role employees mentioned in the this.ohlroles
    //             for (let i = 0; i < res[0].length; i++) {
    //                 const element = res[0][i];
    //                 if (this.ohlroles.includes(element.job_title)) {
    //                     ohlemps.push(element)
    //                     allOHLEmps.push(element)
    //                 }
    //             }
    //             let allGfs = ohlemps.filter(emp => this.gfRoleId.includes(emp.job_title))
    //             // filtering only generalforemen role
    //             // let allGfs = ohlemps.filter(emp => this.gfRoleId.findIndex(role => role === emp.job_title) !== -1)
    //             allGfs = allGfs.filter(emp => !emp.end_date || moment(emp.end_date).isAfter(moment().format()))
    //             let allGangEmps = ohlemps.filter(emp => emp.job_title !== this.gfRoleId)

    //             let filteredGFEmps = allOHLEmps.filter(emp => !emp.end_date || moment(emp.end_date).isAfter(moment().format()))
    //             filteredGFEmps = filteredGFEmps.filter(emp => this.gfRoleId.findIndex(role => role === emp.job_title) !== -1)
    //             debugger

    //             // filtering not Allocated generalforemen
    //             let unAllocatedGF = [...filteredGFEmps];
    //             for (let gfEmpIndex = 0; gfEmpIndex < res[8].length; gfEmpIndex++) {
    //                 let filteredGfNotAllocatedEmps = unAllocatedGF.filter(emp => emp.id !== res[8][gfEmpIndex].ptd_employee)
    //                 unAllocatedGF = filteredGfNotAllocatedEmps
    //             }
    //             // if (unAllocatedGF.length === 0) {

    //             //     unAllocatedGF = [...allGfs];
    //             // }

    //             let rolesObj = {}
    //             res[2].forEach(ele => {

    //                 let newData = { [ele.id]: ele.job_roles }
    //                 rolesObj = { ...rolesObj, ...newData }
    //             })
    //             // getting lookup objects
    //             let ohlEempObj = this.getEmpFomattedLookupObj(ohlemps)
    //             let sec = this.getFomattedLookupObj(res[3])
    //             let reg = this.getFomattedLookupObj(res[4])
    //             let odu = this.getFomattedLookupObj(res[5])
    //             let live = []

    //             for (let index = 0; index < res[6].length; index++) {
    //                 const element = res[6][index];
    //                 if (element.archive_reason === 0) {
    //                     live.push(element)
    //                 }

    //             }


    //             this.setState({
    //                 allGfs,
    //                 allOHLEmps,
    //                 ohlEempObj,
    //                 unAllocatedGF,
    //                 allGangEmps,
    //                 gfs: res[1],
    //                 gfsCopy: res[1],
    //                 rolesObj,
    //                 sector: sec,
    //                 owner_region: reg,
    //                 owner_delivery_unit: odu,
    //                 allElectricityOHLTcRMs: live,
    //                 allGangmen: res[7]
    //             }, () => this.setState({ loading: false, resourceLoading: false }))
    //         })
    //         .catch(e => {
    //             this.setState({ loading: false, resourceLoading: false })
    //         })
    // }

    render() {
        return (
            <div>
            {this.state.loading ? <LoopCircleLoading/> :
            <div>
                <div style={{ display: 'flex', justifyContent: 'flex-start', }}>
                    <IconButton onClick={() => this.setState({ open: true })}>
                        <ReorderIcon />
                    </IconButton>
                    <Drawer anchor='left' open={this.state.open} onClose={() => this.handleClose()}>
                            <Box>
                                <Box margin={2}>

                                    <Tabs
                                        value={this.state.value}
                                        indicatorColor={getPrimaryColor()}
                                        textColor={getPrimaryColor()}
                                        onChange={(event, newValue) => this.handleChange(event, newValue)}
                                        aria-label="OHL Tabs"

                                    >
                                        <Tab label="STAFF" style={{ backgroundColor: this.state.value === 0 ? getPrimaryColor() : null, border: '2px solid ' + getPrimaryColor(), borderRadius: '15px 15px 0 0', color: this.state.value === 0 ? 'white' : null, fontWeight: 'bold' }} />
                                        <Tab label="RESOURCE" style={{ backgroundColor: this.state.value === 1 ? getPrimaryColor() : null, border: '2px solid ' + getPrimaryColor(), borderRadius: '15px 15px 0 0', color: this.state.value === 1 ? 'white' : null, fontWeight: 'bold' }} />
                                        <Tab label="Dashboard" style={{ backgroundColor: this.state.value === 2 ? getPrimaryColor() : null, border: '2px solid ' + getPrimaryColor(), borderRadius: '15px 15px 0 0', color: this.state.value === 2 ? 'white' : null, fontWeight: 'bold' }} />
                                        {/* <Tab label="TRAINING" {...a11yProps(2)} /> */}
                                    </Tabs>
                                </Box>
                                <Box overflowY={"scroll"}>
                                    {/* <TabPanel value={this.state.value} index={0}> */}
                                    <Box margin={2}>
                                        {this.state.value === 0 ?
                                            <OHLStaff
                                                discipline={this.props.discipline}
                                                rolesObj={this.state.rolesObj}
                                                sector={this.state.sector}
                                                owner_region={this.state.owner_region}
                                                owner_delivery_unit={this.state.owner_delivery_unit}
                                                updateParent={this.updateParent}
                                                allGfs={this.state.allGfs}
                                                unAllocatedGF={this.state.unAllocatedGF}
                                                roles={this.props.roles}
                                                gfRoleId={this.props.gfRoleId}
                                                gfs={this.state.gfs}
                                                gfsCopy={this.state.gfsCopy}
                                                ohlEempObj={this.state.ohlEempObj}
                                                data={this.handleData}
                                                option={this.handleOption}
                                                name={this.handleName}
                                                loading={this.state.loading} />
                                            :
                                            this.state.value === 1 ?
                                                <GFs
                                                    discipline={this.props.discipline}
                                                    rolesObj={this.state.rolesObj}
                                                    sector={this.state.sector}
                                                    owner_region={this.state.owner_region}
                                                    owner_delivery_unit={this.state.owner_delivery_unit}
                                                    gfs={this.state.gfs}
                                                    gfsCopy={this.state.gfsCopy}
                                                    ohlEempObj={this.state.ohlEempObj}
                                                    data={this.handleResourceData}
                                                    name={this.handleName}
                                                    loading={this.state.resourceLoading} />
                                                : null}
                                    </Box>

                                    {/* </TabPanel> */}
                                    {/* <TabPanel value={this.state.value} index={1}> */}
                                    {/* </TabPanel> */}
                                </Box>
                            </Box>
                    </Drawer>

                </div>
                {!this.state.ohlLoadin && this.state.value === 0 ? <OHLStaffTables rowData={this.state.rowData} roles={this.props.roles} gfRoleId={this.props.gfRoleId} discipline={this.props.discipline} loading={this.state.loading} option={this.state.option} name={this.state.name} /> : null}
                {!this.state.gfLoading && this.state.value === 1 ? <GfsTable rowData={this.state.resourceRowData} roles={this.props.roles} gfRoleId={this.props.gfRoleId} discipline={this.props.discipline} name={this.state.name} /> : null}
                {/* <TabPanel value={this.state.value} index={2}> */}
                {this.state.value === 2 ? <OHLDashboard roles={this.props.roles} gfRoleId={this.props.gfRoleId} discipline={this.props.discipline} /> : null}
                {/* </TabPanel> */}

            </div>}
            </div>
        )
    }
}

export default OHLHomeClass
