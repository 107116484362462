import React from 'react';
import MaterialTable from 'material-table'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { withSnackbar } from 'notistack';
import Service from '../../config/networkutils';
import UserPermissions from './UserPermission';
import RotateCircleLoading from 'react-loadingg/lib/RotateCircleLoading';
import Auth from '../../utils/authenticate';
const token = Auth.token()

class UserAccess extends React.Component {
    constructor() {
        super()
        this.state = {
            columns: [
                { title: 'First Name', field: 'first_name' },
                { title: 'Last Name', field: 'last_name' },
                { title: 'Email', field: 'email', editable: 'never' },
                // { title: 'Location', field: 'location' },
                { title: 'Active Status', field: 'is_active', lookup: { false: 'Inactive', true: 'Active' } },
            ],
            data: [],
            userAddModal: false,
            first_name: '',
            last_name: '',
            email: '',
            isLoading: false,
        }
        this.closeUserAddModal = this.closeUserAddModal.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {
        this.fetchOps()
    }

    fetchOps() {
        this.setState({ isLoading: true })
        Service.get(`/api/users/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ data: res.data })
                this.setState({ isLoading: false })
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                this.setState({ isLoading: false })
            });
    }

    putOps(data) {
        this.setState({ isLoading: true })
        Service.put(`/api/users/` + data.id, {
            headers: {
                Authorization: "Token " + token,
            },
            data,
        })
            .then(res => {
                this.fetchOps()
                this.props.enqueueSnackbar('Successfully Updated', {
                    variant: 'success'
                });
            })
            .catch(error => {
                this.setState({ isLoading: false })
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                this.setState({ isLoading: false })
            });

    }

    closeUserAddModal() {
        this.setState({ userAddModal: false })
    }

    handleChange(e) {
        let { name, value } = e.target
        this.setState({ [name]: value })
    }


    addUser() {

        if (this.state.first_name === '' || this.state.last_name === '' || this.state.email === '') {
            this.props.enqueueSnackbar('All the fields are required.', {
                variant: 'warning',
            });
        }
        else if (!this.state.email.includes("@balfourbeatty.com")) {

            this.props.enqueueSnackbar('Only Balfour Beatty email address is allowed.', {
                variant: 'warning',
            });
        }
        else {

            var json = {
                email: this.state.email.toLowerCase(),
                password1: 'Password.1',
                password2: 'Password.1',
            }
            var json2 = {
                email: json.email,
                email_username: json.email,
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                is_staff: this.state.access === 10 || this.state.access === 20 || this.state.access === 30,
                is_lead: this.state.access === 20 || this.state.access === 30,
                is_superuser: false,
                is_admin: this.state.access === 30,
                twof_auth: false,
                is_estimator: false,
                is_hr: false,
                is_active: true,
                // role_id: autocompdata !== "" ? autocompdata.id : null,
            }
            this.setState({ isLoading: true })
            Service.post('/rest-auth/registration/', {
                data: json,
            })
                .then(res => {
                    Service.put("/api/request/password/" + json.email + "/?email=" + json.email, {
                        headers: {
                            Authorization: "Token " + token,
                        },
                        data: json2,
                    })
                        .then(res1 => {
                            this.setState({ userAddModal: false, isLoading: false });
                            this.props.enqueueSnackbar(json.email + ' is created', {
                                variant: 'success',
                            });
                            this.fetchOps();
                        })
                        .catch(error => {
                            this.setState({ isLoading: false });
                            console.log(error)
                            this.props.enqueueSnackbar('Something went wrong!', {
                                variant: 'error'
                            });
                        });

                })
                .catch(error => {

                    if (error.data.email) {
                        this.props.enqueueSnackbar(error.data.email[0], {
                            variant: 'warning',
                        });
                    } else if (error.data.password1) {
                        this.props.enqueueSnackbar(error.data.password1[0], {
                            variant: 'warning',
                        });
                    } else {
                        this.setState({ isLoading: false });
                        this.props.enqueueSnackbar('Something went wrong!. Try after sometime.', {
                            variant: 'error',
                        });
                    }
                });
        }
    }


    render() {
        // const access = this.props.access.access
        return (
            <>
                {this.state.isLoading ? <RotateCircleLoading color="#005D99" /> :
                    <div>
                        <MaterialTable
                            title={'Users & Access'}
                            columns={this.state.columns}
                            data={this.state.data}
                            options={{
                                paging: false,
                                pageSize: 20,
                                pageSizeOptions: [10, 20, 50, 100],
                                maxBodyHeight: window.screen.height - 250,
                                headerStyle: {
                                    fontWeight: 'bold',
                                    // color: '#005D99',
                                },
                                actionsColumnIndex: -1,
                                filtering: true
                            }}
                            editable={{
                                // onRowUpdate: access.l8a && access.l8a.l8a_b ? (newData, oldData) =>
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve, reject) => {
                                        setTimeout(() => {

                                            this.putOps(newData)
                                            resolve();
                                        }, 1000)
                                    }),
                            }}
                            detailPanel={[
                                {
                                    render: rowData => {
                                        return (
                                            <div style={{ padding: '15px' }}>
                                                {/* <UserPermissions value={rowData} edit={access.l8a && access.l8a.l8a_b} /> */}
                                                <UserPermissions value={rowData} edit={true} />
                                            </div>
                                        )
                                    },
                                }
                            ]}
                            onRowClick={(event, rowData, togglePanel) => togglePanel()}
                            // actions={access.l8a && access.l8a.l8a_b ? [
                            actions={[
                                {
                                    icon: 'add',
                                    tooltip: 'Add User',
                                    isFreeAction: true,
                                    onClick: (event) => {
                                        this.setState({ userAddModal: true })
                                    }
                                },

                                // {
                                //     icon: 'edit',
                                //     tooltip: 'Edit',
                                //     // isFreeAction: true,
                                //     onClick: (event, rowData) => {
                                //         this.setState({ userAddModal: true })
                                //     }
                                // },
                            ]}
                        />

                        <Dialog
                            open={this.state.userAddModal}
                            onClose={this.closeUserAddModal}
                            aria-labelledby="form-dialog-title"
                            maxWidth="xs"
                        >
                            <DialogTitle id="form-dialog-title">Add User</DialogTitle>
                            <DialogContent>
                                <TextField
                                    autoFocus
                                    margin="normal"
                                    id="first_name"
                                    name="first_name"
                                    label="First Name"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    onChange={this.handleChange}
                                />
                                <TextField
                                    autoFocus
                                    margin="normal"
                                    id="last_name"
                                    name="last_name"
                                    label="Last Name"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    onChange={this.handleChange}
                                />
                                <TextField

                                    margin="normal"
                                    id="email"
                                    name="email"
                                    label="Email Address"
                                    type="email"
                                    fullWidth
                                    variant="outlined"
                                    onChange={this.handleChange}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.closeUserAddModal} color="secondary">
                                    Cancel
                                </Button>
                                <Button onClick={this.addUser.bind(this)} color="primary">
                                    Add
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                }
            </>
        )
    }
}

export default withSnackbar(UserAccess);