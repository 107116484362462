import React from 'react'
import MaterialTable from 'material-table';
import Service from '../../config/networkutils';
import { withSnackbar } from 'notistack';
import RotateCircleLoading from 'react-loadingg/lib/RotateCircleLoading';
import { Accordion, AccordionDetails, AccordionSummary, Button, TextField, Typography } from '@material-ui/core';
import UploadIcon from '@material-ui/icons/CloudUpload'
import XLSX from "xlsx";
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { employees_and_projects, users_api, users_api_bulk_update, prp_template_api_bulk_update, getPromotion, get_users_api } from '../../config/apiList';
import Modal from '../../shared/Modal/Modal';
import ExcelDateToJSDate from '../../utils/excelDateToJSDate';
import Dropzone from '../../shared/Dropzone/Dropzone';
import { getCookie } from '../../utils/CookieHelper';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import AutcompleteInput from '../../shared/AutocompleteInput/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Auth from '../../utils/authenticate';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { changeItToMonday } from '../../utils/betweenDates';
import { removeAnElementFromTheArray, removeAnElementFromTheArrayAndAdd, applyQuantityToEveryWeek, getLookupObj, customFilter } from '../../utils/reuseFunctions';
import { ExpandMore } from '@material-ui/icons';
import { getMarketSubVerticle, getOwnerDelivery, getRegion, getRoles, getSubSector } from '../../config/PromisesHandler';
import { Box, Paper, Tab, Tabs } from "@material-ui/core";
import PropTypes from 'prop-types';
import { Alert, Autocomplete } from '@material-ui/lab';
import Export from './Export';
import PromotionHistory from '../PromotionHistory/PromotionHistory';
import PromotionUnassignedSchedules from './PromotionunassignedSchedules';

const token = getCookie("ptd_ts_token");
const user = Auth.user();

const useStyles = (theme) => ({
});

const ScheduleMaker = ({ schedule }) => {
    return (
        <div style={{ paddingBottom: '1rem' }}><Typography>{`${schedule.from.slice(0, 10)} To ${schedule.to.slice(0, 10)}  Qty : ${schedule.qty}`}</Typography></div>

    )
}

const SchedulesContainer = ({ schedules }) => {
    return (
        < >
            {schedules.map((shdl, shdlIndex) => <ScheduleMaker key={shdlIndex} schedule={shdl} />)}
        </>
    )
}

const HistorySchedulesContainer = ({ crm_name, schedules }) => {
    return (
        < div style={{ margin: '1rem' }}>
            < h2 > {crm_name}</h2>
            {schedules.map((shdl, shdlIndex) => <ScheduleMaker key={shdlIndex} schedule={shdl} />)}
        </div>
    )
}

// const sortObjectEntries = (obj) =>{
//     return  Object.entries(obj).sort((a,b)=>b[1]-a[1])
// }

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    // let _componentStatus.current = useRef(true);
    // useEffect(() => {
    //     let componentMounted = true;
    //       const fetchData = async () => {
    //        //you async action is here
    //         if(componentMounted) {
    //           setData(response?.data);
    //         }
    //       };
    //       fetchData();
    //       return () => {
    //        componentMounted = false;
    //       }
    //     }, []);
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-prevent-tabpanel-${index}`}
            aria-labelledby={`scrollable-prevent-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'} variant={'body2'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

class Users extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            columns: [],
            data: [],
            isLoading: true,
            owner_delivery_unit: {},
            owner_region: {},
            sector: {},
            job_titles: {},
            reviewModal: false,
            dataToUpload: [],
            dropZoneModal: false,
            view_type: 'Live',
            market_subvertical: {},
            regionChangeDialog: false,
            selectedRegion: null,
            regionChangeDate: moment(),
            ownerRegionArray: [],
            selectedEmp: {},
            regionHistoryDialog: false,
            regionHistory: [],
            global_assign: false,
            promotionDialog: false,
            promoDescription: '',
            promotionDate: moment(),
            selectedRole: null,
            rolesArray: [],
            selectedPrps: [],
            verifyPromotionChanges: false,
            verifySchedules: [],
            promotionHistory: [],
            promotionHistorySchedules: false,
            tabValue: 0,
            postData: {
                emp_no: null,
                first_name: null,
                last_name: "",
                job_title: '',
                email: null,
                owner_region: null,
                owner_delivery_unit: null,
                sector: null,
                market_subvertical: null,
                start_date: null,
                end_date: null
            },
            ownerDelivery: [],
            subSector: [],
            marketSubVertical: [],
            addDisabled: false,
            nextDisabled: true,
            type: 'add',
            job_titlesID: [],
            endDateDialog: false,
            endDateSubmitDisabled: false,
            promotionHistoryDialog: false,
            promotionGraphDialog: false,
            unassignedSchedules: [],
        }
        this.closeDialog = this.closeDialog.bind(this)
        this.closePromotionDialog = this.closePromotionDialog.bind(this)
    }

    componentDidMount() {
        // this.setState({ isLoading: true })
        // this.fetchOps()

        Promise.all([
            getOwnerDelivery(),
            getRegion(),
            getSubSector(),
            getMarketSubVerticle(),
            getRoles(),

        ])
            .then(res => {
                let newArr = []
                for (let i = 0; i < 4; i++) {
                    let formattedObj = getLookupObj(res[i]);
                    newArr.push(formattedObj)
                }

                let liveRoles = res[4].filter(ele => ele.is_active === true)

                let obj = {}
                let roleObjID = {}
                liveRoles.forEach(ele => {
                    obj = { ...obj, ...{ [ele.job_roles]: ele.job_roles } }
                })

                liveRoles.forEach(ele => {
                    roleObjID = { ...roleObjID, ...{ [ele.id]: ele.job_roles } }
                })


                this.setState({
                    ownerDelivery: res[0],
                    subSector: res[2],
                    marketSubVertical: res[3],
                    owner_delivery_unit: newArr[0],
                    owner_region: newArr[1],
                    ownerRegionArray: res[1],
                    sector: newArr[2],
                    market_subvertical: newArr[3],
                    job_titles: obj,
                    job_titlesID: roleObjID,
                    rolesArray: liveRoles
                }, () => { this.fetchOps() })
            })
            .catch(e => {
                this.setState({ isLoading: false })
            })
    }

    getPromotionHistory(employee) {
        Service.get(`${getPromotion}?ptd_employee=${employee.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        }).then(res => { this.setState({ promotionHistory: res.data }) })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' })
            })

    }

    fetchEmployeeProjects(empId) {
        this.setState({ isLoading: true })
        Service.get(employees_and_projects + `?is_archive=0&emp_id=${empId}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                this.setState({ PromtingEmpdata: res.data })
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
            .finally(() => {
                setTimeout(() => {
                    this.setState({ isLoading: false })
                }, 1000);
            });
    }

    fetchOps() {
        this.setState({ isLoading: true })
        Service.get(get_users_api, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                let resData = res.data;
                let archive = []; let live = [];
                resData.forEach(emp => {
                    if (moment(emp.end_date).isBefore(moment().format())) {
                        archive.push(emp)
                    }
                    else live.push(emp)
                })

                this.setState({ live, archive, isLoading: false })
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
            .finally(() => {
                setTimeout(() => {
                    this.setState({ isLoading: false })
                }, 1000);
            });
    }

    postOps(postData) {
        // for (let x in data) {
        //     if (data[x] === "null") {
        //         data[x] = null
        //     }
        // }
        let fields = ""
        if (!postData.emp_no) {
            fields += "Employee Number"
        }
        if (!postData.first_name) {
            fields += !fields ? "First Name" : ", First Name"
        }
        if (!postData.last_name) {
            fields += !fields ? "Last Name" : ", Last Name"
        }
        if (!postData.job_title) {
            fields += !fields ? "Job Title" : ", Job Title"
        }
        // if (!postData.email) {
        //     fields += !fields ? "Email" : ", Email"
        // }
        if (!postData.owner_region) {
            fields += !fields ? "Owner Region" : ", Owner Region"
        }
        if (!postData.owner_delivery_unit) {
            fields += !fields ? "Owner Delivery Unit" : ", Owner Delivery Unit"
        }
        if (!postData.sector) {
            fields += !fields ? "Sector" : ", Sector"
        }
        if (!postData.start_date) {
            fields += !fields ? "Start Date" : ", Start Date"
        }

        if (!fields) {
            this.setState({ addDisabled: true })
            Service.post(users_api, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: { ...postData, ...{ global_assign: this.state.global_assign ? 1 : 0 } },
            })
                .then(res => {
                    this.setState({
                        postData: {
                            emp_no: null,
                            first_name: null,
                            last_name: "",
                            job_title: '',
                            email: null,
                            owner_region: null,
                            owner_delivery_unit: null,
                            sector: null,
                            market_subvertical: null,
                            start_date: null,
                            end_date: null
                        },
                        addDisabled: false,
                        addUser: false
                    })
                    this.fetchOps();
                    this.props.enqueueSnackbar('Client added successfully', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    this.setState({ isLoading: false, addDisabled: false })
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                })
        } else {
            this.props.enqueueSnackbar(fields + " is Empty!", {
                variant: 'warning'
            });
        }
        // .finally(() => { this.setState({ isLoading: false }) });;
    }

    putOps(postData) {
        let fields = ""
        if (!postData.emp_no) {
            fields += "Employee Number"
        }
        if (!postData.first_name) {
            fields += !fields ? "First Name" : ", First Name"
        }
        if (!postData.last_name) {
            fields += !fields ? "Last Name" : ", Last Name"
        }
        if (!postData.job_title) {
            fields += !fields ? "Job Title" : ", Job Title"
        }
        // if (!postData.email) {
        //     fields += !fields ? "Email" : ", Email"
        // }
        if (!postData.owner_region) {
            fields += !fields ? "Owner Region" : ", Owner Region"
        }
        if (!postData.owner_delivery_unit) {
            fields += !fields ? "Owner Delivery Unit" : ", Owner Delivery Unit"
        }
        if (!postData.sector) {
            fields += !fields ? "Sector" : ", Sector"
        }
        if (!postData.start_date) {
            fields += !fields ? "Start Date" : ", Start Date"
        }

        if (!fields) {
            this.setState({ addDisabled: true })
            Service.put(users_api + postData.id + "/", {
                headers: {
                    Authorization: "Token " + token,
                },
                data: postData,
            })
                .then(res => {
                    this.fetchOps()
                    this.setState({
                        postData: {
                            emp_no: null,
                            first_name: null,
                            last_name: null,
                            job_title: null,
                            email: null,
                            owner_region: null,
                            owner_delivery_unit: null,
                            sector: null,
                            market_subvertical: null,
                            start_date: null,
                            end_date: null
                        },
                        addDisabled: false,
                        addUser: false
                    })
                    this.props.enqueueSnackbar('Successfully Updated', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    this.setState({ isLoading: false, addDisabled: false })
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                    this.fetchOps();
                });
        } else {
            this.props.enqueueSnackbar(fields + " is Empty!", {
                variant: 'warning'
            });
        }
    }

    deleteOps(data) {
        Service.delete(users_api + data.id + "/", {
            headers: {
                Authorization: "Token " + token,
            }
        })
            .then(res => {
                this.fetchOps()
                this.props.enqueueSnackbar('Deleted successfully', {
                    variant: 'success'
                });
            })
            .catch(error => {
                this.setState({ isLoading: false })
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                this.fetchOps();
            });
    }

    usersBulkPost(data) {
        Service.post(users_api, {
            headers: {
                Authorization: "Token " + token,
            },
            data
        })
            .then(res => {
                this.props.enqueueSnackbar(res.data.length + (res.data.length === 1 ? 'Employee' : ' Employees') + ' added successfully', {
                    variant: 'success'
                })
                this.setState({
                    reviewModal: false,
                    dataToUpload: [],
                    dropZoneModal: false,
                    bulkUploadLoader: false
                })
                this.fetchOps()
            })
            .catch(error => {
                this.setState({ bulkUploadLoader: false })
                if (error.status === 400) {
                    if (error.data.length) {
                        for (let i = 0; i < error.data.length; i++) {
                            const err = error.data[i]
                            let hasError = Object.keys(err).length !== 0 && err.constructor === Object;
                            if (hasError) {
                                for (const e in err) {
                                    this.props.enqueueSnackbar("Upload error : " + (data[i]['emp_no'] ? data[i]['emp_no'] : '') + " - " + e + " (" + err[e] + ")", {
                                        variant: 'error'
                                    });
                                    return;
                                }
                            }
                        }

                    }
                }
                else {
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                }

            })

    }

    usersBulkUpdate(data) {
        Service.patch(users_api_bulk_update, {
            headers: {
                Authorization: "Token " + token,
            },
            data
        })
            .then(res => {
                this.props.enqueueSnackbar(res.data.length + (res.data.length === 1 ? ' Employee' : ' Employees') + ' updated successfully', {
                    variant: 'success'
                });
                this.setState({
                    reviewModal: false,
                    dataToUpload: [],
                    dropZoneModal: false,
                })
                this.fetchOps()
            })
            .catch(e => {
                console.error(e)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
            .finally(() => {
                this.setState({ bulkUploadLoader: false })
            })

    }

    reviewModalOpen = () => { this.setState({ reviewModal: true }) }

    reviewModalClose = () => { this.setState({ reviewModal: false }) }

    fileToRead(files) {
        if (!files || !files[0]) return;
        let f = files[0];
        try {
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;

            reader.onload = (e) => {
                /* read workbook */
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: 'binary' });

                /* grab first sheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];

                /* save data */
                const data = XLSX.utils.sheet_to_json(ws)

                data.forEach(ele => {
                    if (ele.start_date) ele.start_date = ExcelDateToJSDate(ele.start_date);
                    if (ele.end_date) ele.end_date = ExcelDateToJSDate(ele.end_date);
                    if (ele.job_title) {
                        for (let k in this.state.job_titlesID) {
                            if (String(this.state.job_titlesID[k]).trim() === String(ele.job_title).trim()) {
                                ele.job_title = k
                            }
                        }
                    }
                    if (ele.owner_region) {
                        for (let k in this.state.owner_region) {
                            if (String(this.state.owner_region[k]).trim() === String(ele.owner_region).trim()) {
                                ele.owner_region = k
                            }
                        }
                    }
                    if (ele.owner_delivery_unit) {
                        for (let k in this.state.owner_delivery_unit) {
                            if (String(this.state.owner_delivery_unit[k]).trim() === String(ele.owner_delivery_unit).trim()) {
                                ele.owner_delivery_unit = k
                            }
                        }
                    }
                    if (ele.sector) {
                        for (let k in this.state.sector) {
                            if (String(this.state.sector[k]).trim() === String(ele.sector).trim()) {
                                ele.sector = k
                            }
                        }
                    }
                    if (ele.market_subvertical) {
                        for (let k in this.state.market_subvertical) {
                            if (String(this.state.market_subvertical[k]).trim() === String(ele.market_subvertical).trim()) {
                                ele.market_subvertical = k
                            }
                        }
                    }
                })
                this.setState({ dataToUpload: data })
            };
            if (rABS) {
                reader.readAsBinaryString(f);
            } else {
                reader.readAsArrayBuffer(f);
            }
        }
        catch (e) {
            console.error(e)
        }
    }

    bulkUpload = () => {
        this.setState({ bulkUploadLoader: true })
        let dataToUpload = [...this.state.dataToUpload]
        let dataToUpdate = []
        dataToUpload.forEach((ele, i) => {
            this.state.data.forEach(emp => {
                if (String(ele.emp_no) === String(emp.emp_no)) {
                    ele.id = emp.id;
                    dataToUpdate.push(ele)
                }
            })
        })
        dataToUpdate.forEach((ele) => {
            dataToUpload.forEach((ele2, i) => {
                if (String(ele.emp_no) === String(ele2.emp_no)) {
                    dataToUpload.splice(i, 1)
                }
            })
        })

        if (dataToUpload.length) {

            let arr = [];
            for (let i = 1; i <= dataToUpload.length; i++) {
                arr.push(dataToUpload[i - 1])
                if (i === 1000 * Number((i / 1000).toFixed())) {
                    this.usersBulkPost(arr)
                    arr = []
                }
                if (i === dataToUpload.length) {
                    this.usersBulkPost(arr)
                }
            }


        }
        if (dataToUpdate.length) {

            let arr = [];
            for (let i = 1; i <= dataToUpdate.length; i++) {
                arr.push(dataToUpdate[i - 1])
                if (i === 1000 * Number((i / 1000).toFixed())) {
                    this.usersBulkUpdate(arr)
                    arr = []
                }
                if (i === dataToUpdate.length && arr.length) {
                    this.usersBulkUpdate(arr)
                }
            }
        }

        // setTimeout(() => {
        //     this.setState({
        //         reviewModal: false,
        //         dataToUpload: [],
        //         dropZoneModal: false,
        //         bulkUploadLoader: false
        //     })
        //     this.fetchOps()
        // }, 4000);

    }

    dropzoneClose = () => {
        this.setState({ dropZoneModal: false })
    }

    handleViewType(e, newValue) {

        if (newValue === 0) {
            this.setState({ tabValue: newValue })
        }
        if (newValue === 1) {
            this.setState({ tabValue: newValue })
        }
    }

    promotionDialogClose = () => {
        this.setState({ promotionDialog: false, selectedRole: '', selectedEmp: '', promotionDate: moment(), promotionDescription: '' })
    }

    promotionHistoryDialogClose = () => {
        this.setState({ promotionHistoryDialog: false, })
    }

    regionChangeDialogClose = () => {
        this.setState({ regionChangeDialog: false })
    }

    getRegionChangeInfo(data) {
        // this.setState({ isLoading: true })
        Service.get('/api/ptd/history/employees/?ptd_employee=' + data.id, {
            headers: {
                Authorization: "Token " + token,
            }
        })
            .then(res => {

                this.setState({ regionHistoryDialog: true, regionHistory: res.data })
            })
            .catch(error => {
                this.setState({ isLoading: false, })
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    regionHistoryDialogClose = () => {
        this.setState({ regionHistoryDialog: false })
    }

    promotionOps(data) {
        this.fetchEmployeeProjects(data.id)
        this.setState({
            promotionDialog: true,
        })

    }

    regionChangeOps(data) {
        Service.get('/api/ptd/history/employees/?ptd_employee=' + data.id, {
            headers: {
                Authorization: "Token " + token,
            }
        })
            .then(res => {

                this.setState({
                    selectedEmp: data,
                    regionHistory: res.data,
                    selectedRegion: this.state.ownerRegionArray.find(ele => ele.id === data.owner_region),
                    regionChangeDialog: true,
                })
            })
            .catch(error => {
                this.setState({ isLoading: false, })
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    alterSchedules() {

        let promotionDate = this.state.promotionDate
        let scheduleChangeProjects = JSON.parse(JSON.stringify(this.state.selectedPrps))
        let oldScheduleChangeProjects = JSON.parse(JSON.stringify(this.state.selectedPrps))
        let newScheduleChangeProjects = JSON.parse(JSON.stringify(this.state.selectedPrps))
        let verifySchedules = []

        for (let projectIndex = 0; projectIndex < scheduleChangeProjects.length; projectIndex++) {
            let oldCRMSchedules = []
            let newCRMSchedules = []
            let curentCRMSchedules = []
            for (let shdlIndex = 0; shdlIndex < scheduleChangeProjects[projectIndex].shdl_data?.length; shdlIndex++) {
                curentCRMSchedules.push(scheduleChangeProjects[projectIndex].shdl_data[shdlIndex])
                //    Find If promotion date is before from date
                if (scheduleChangeProjects[projectIndex].shdl_data[shdlIndex].from &&
                    scheduleChangeProjects[projectIndex].shdl_data[shdlIndex].to
                    && promotionDate) {
                    if (moment(scheduleChangeProjects[projectIndex].shdl_data[shdlIndex].from.slice(0, 10)).isSameOrAfter(
                        moment(promotionDate, "MM/dd/yyyy").toISOString().slice(0, 10))) {
                        oldScheduleChangeProjects[projectIndex]['shdl_data'] = removeAnElementFromTheArray(oldScheduleChangeProjects[projectIndex]['shdl_data'], scheduleChangeProjects[projectIndex].shdl_data[shdlIndex])
                        newCRMSchedules.push(scheduleChangeProjects[projectIndex].shdl_data[shdlIndex])
                    }
                    //    Find If promotion date is before to date
                    else if (moment(scheduleChangeProjects[projectIndex].shdl_data[shdlIndex].to.slice(0, 10)).isSameOrBefore(
                        moment(promotionDate, "MM/dd/yyyy").toISOString().slice(0, 10))) {
                        newScheduleChangeProjects[projectIndex]['shdl_data'] = removeAnElementFromTheArray(newScheduleChangeProjects[projectIndex]['shdl_data'], scheduleChangeProjects[projectIndex].shdl_data[shdlIndex])
                        oldCRMSchedules.push(scheduleChangeProjects[projectIndex].shdl_data[shdlIndex])
                    }
                    //    Find If promotion date is between from and to date
                    else if (moment(promotionDate, "MM/dd/yyyy").isBetween(scheduleChangeProjects[projectIndex].shdl_data[shdlIndex].from.slice(0, 10), scheduleChangeProjects[projectIndex].shdl_data[shdlIndex].to.slice(0, 10))
                    ) {
                        oldScheduleChangeProjects[projectIndex]['shdl_data'] = removeAnElementFromTheArrayAndAdd(oldScheduleChangeProjects[projectIndex]['shdl_data'], scheduleChangeProjects[projectIndex].shdl_data[shdlIndex], {
                            from: scheduleChangeProjects[projectIndex].shdl_data[shdlIndex].from,
                            to: changeItToMonday(moment(promotionDate, "MM/dd/yyyy").subtract(7, 'days')),
                            qty: scheduleChangeProjects[projectIndex].shdl_data[shdlIndex].qty
                        })
                        oldCRMSchedules.push({
                            from: scheduleChangeProjects[projectIndex].shdl_data[shdlIndex].from,
                            to: changeItToMonday(moment(promotionDate, "MM/dd/yyyy").subtract(7, 'days')),
                            qty: scheduleChangeProjects[projectIndex].shdl_data[shdlIndex].qty
                        })

                        newScheduleChangeProjects[projectIndex]['shdl_data'] = removeAnElementFromTheArrayAndAdd(newScheduleChangeProjects[projectIndex]['shdl_data'], scheduleChangeProjects[projectIndex].shdl_data[shdlIndex], {
                            from: moment(promotionDate, "MM/dd/yyyy").toISOString(),
                            to: scheduleChangeProjects[projectIndex].shdl_data[shdlIndex].to,
                            qty: scheduleChangeProjects[projectIndex].shdl_data[shdlIndex].qty
                        })
                        newCRMSchedules.push({
                            from: moment(promotionDate, "MM/dd/yyyy").toISOString(),
                            to: scheduleChangeProjects[projectIndex].shdl_data[shdlIndex].to,
                            qty: scheduleChangeProjects[projectIndex].shdl_data[shdlIndex].qty
                        })
                    }
                }

            }

            verifySchedules.push({ crm: scheduleChangeProjects[projectIndex].crm_name, oldCRMSchedules, newCRMSchedules, curentCRMSchedules })
        }
        // oldScheduleChangeProjects = applyQuantityToEveryWeek(oldScheduleChangeProjects)
        newScheduleChangeProjects = applyQuantityToEveryWeek(newScheduleChangeProjects)
        this.setState({ verifySchedules, oldScheduleChangeProjects, newScheduleChangeProjects, verifyPromotionChanges: true })
    }

    createOldSchedules(data) {
        return new Promise((resolve, reject) => {
            Service.post('/api/ptd/employee/promotion/employee_role_upgrade2/', { headers: { Authorization: "Token " + token }, data }).then(res => resolve(res.data)).catch(error => { reject(error); console.log(error) })
        })
    }

    updateNewSchedules(data) {
        return new Promise((resolve, reject) => {
            Service.patch(prp_template_api_bulk_update, {
                headers: {
                    Authorization: "Token " + token,
                },
                data,
            }).then(res => resolve(res.data)).catch((error => reject(error)))
        })
    }

    submitPromotion(state) {
        let promoData = {
            emp: state.selectedEmp.id,
            role: state.selectedRole.id,
            migration_taken: { previousRole: { role: state.selectedEmp.job_title, schdl_data: state.oldScheduleChangeProjects }, promotionDate: state.promotionDate, Description: state.promoDescription }
        }
        Promise.all([this.createOldSchedules(promoData)]).then(res => {
            this.fetchOps();
            this.promotionDialogClose()
            this.setState({ verifyPromotionChanges: false, promoDescription: '' })
            this.props.enqueueSnackbar("promoting Employee Success!", {
                variant: "success",
            });
        }).catch((error) => {
            console.log(error)
            this.props.enqueueSnackbar("Promoting employee failed!", {
                variant: "error",
            });
        });
    }

    submitRegionChange() {
        let oldRegion;
        for (let i = 0; i < this.state.regionHistory.length; i++) {
            if (moment(this.state.regionHistory[i].changed_on).isBefore(this.state.regionChangeDate)) {
                oldRegion = this.state.regionHistory[i].new_owner_region
            }
        }
        if (oldRegion === this.state.selectedRegion.id) {
            this.props.enqueueSnackbar('Selected region can not be same as previous region ', {
                variant: 'warning'
            });
            return;
        }
        let newData = { ...this.state.selectedEmp, ...{ owner_region: this.state.selectedRegion.id } }

        Service.post('/api/ptd/history/employees/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                changed_on: this.state.regionChangeDate,
                new_owner_region: this.state.selectedRegion.id,
                current_owner_region: this.state.selectedEmp.owner_region,
                ptd_employee: this.state.selectedEmp.id,
                created_by: user.id
            },
        })
            .then(res => {
                if (moment(this.state.regionChangeDate).isSameOrBefore()) {
                    this.putOps(newData)
                }
                this.regionChangeDialogClose()
                this.props.enqueueSnackbar('Region updated successfully', {
                    variant: 'success'
                });
            })
            .catch(error => {
                this.setState({ isLoading: false })
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    handleTextChange(e) {
        let postData = this.state.postData
        postData[e.target.id] = e.target.value
        this.setState({ postData })
    }

    handleSelectChange = (id, values) => {
        var postData = this.state.postData
        if (values) {
            postData[id] = values.id
        } else {
            postData[id] = values
        }
        this.setState({ postData });
    }

    assignEndDate(postData) {
        if (!postData.end_date) {
            this.props.enqueueSnackbar('Please select End Date', {
                variant: 'warning'
            });
        } else {
            this.setState({
                endDateSubmitDisabled: true
            })
            Service.patch(`/api/role/schedule/leavers_allocation_removal/?emp=${postData.id}`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    emp_id: postData.id,
                    leave_date: postData.end_date
                },
            })
                .then(res => {
                    this.setState({ isLoading: true })
                    this.fetchOps()
                    this.setState({
                        endDateDialog: false
                    })
                    this.setState({
                        endDateSubmitDisabled: false
                    })
                    this.props.enqueueSnackbar('End Date Assigned Successfully', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    this.setState({ endDateDialog: false })
                    if(error.status === 406){
                        this.props.enqueueSnackbar('Assigned End Date and Employee End Date is Same', {
                            variant: 'error'
                        });
                    }else{
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                }
                })
        }
    }
    getUnassignedSchedules() {
        Service.get(`/api/ptd/employee/promotion/get_unassinged_schlds_for_future_roles/?emp_id=${this.state.selectedEmp.id}&promoted_role_id=${this.state.selectedRole.id}&promotion_date=${moment(this.state.promotionDate).format("YYYY-MM-DD")}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    unassignedSchedules: res.data
                }, () => {
                    this.setState({ promotionGraphDialog: true })
                })
            })
            .catch(error => {
                this.setState({ isLoading: false })
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    closeDialog() {
        this.setState({ promotionHistoryDialog: false })
    }

    closePromotionDialog(value) {
        this.setState({ promotionGraphDialog: false, })
        if (value) {
            this.setState({ promotionDialog: false, isLoading: true })
            this.fetchOps()
        }
    }

    render() {
        const access = this.props.access.access

        // const customFilter = (term, rowData, field) => {
        //     let search_words = term.toLowerCase().split(" ")
        //     let search_match = search_words.filter(item => rowData[field].toLowerCase().includes(item))
        //     return search_match.length > 0
        // }

        const addDialogClose = () => {
            this.setState({
                addUser: false,
                postData: {
                    emp_no: null,
                    first_name: null,
                    last_name: null,
                    job_title: null,
                    email: null,
                    owner_region: null,
                    owner_delivery_unit: null,
                    sector: null,
                    market_subvertical: null,
                    start_date: null,
                    end_date: null
                },
            })
        }

        return (<>
            {this.state.isLoading ? <RotateCircleLoading color="#005D99" /> :
                <div>

                    <Paper square>
                        <Tabs
                            value={this.state.tabValue}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={(e, newValue) => this.handleViewType(e, newValue)}
                            aria-label="Role Tabs"
                            centered
                        >
                            <Tab label="Live" />
                            <Tab label="Archive" />
                        </Tabs>
                        <TabPanel value={this.state.tabValue} index={0}>

                            <MaterialTable
                                title={<>
                                    <Box display={'flex'} flexDirection={"row"} justifyContent={'space-around'} alignItems={'center'}>
                                        <h2 style={{ float: 'left' }}>Employees</h2>&emsp;&emsp;
                                        {access.l4a && access.l4a.l4a_d ?
                                            <React.Fragment>
                                                <Button
                                                    variant="outlined"
                                                    size="small"
                                                    style={{ marginLeft: '80px' }}
                                                    onClick={() => this.setState({ dropZoneModal: true })}
                                                >
                                                    Upload Employee Data Excel&ensp;<UploadIcon />
                                                </Button>
                                                <FormControlLabel
                                                    style={{ marginLeft: '20px', }}
                                                    control={<Switch checked={this.state.global_assign} onChange={(e) => { this.setState({ global_assign: e.target.checked }) }} name="global_assign" />}
                                                    label="Global Assign"
                                                />
                                                <Export allData={true} />
                                            </React.Fragment> : null}
                                    </Box>
                                </>}
                                options={{
                                    // paging: false,
                                    pageSize: 20,
                                    pageSizeOptions: [20, 50, 100, 200, 500, 1000],
                                    // doubleHorizontalScroll: true,
                                    // maxBodyHeight: window.screen.height - 250,
                                    headerStyle: {
                                        fontWeight: 'bold',
                                        // color: '#005D99',
                                    },
                                    // fixedColumns: {
                                    //     left: 0,
                                    //     right: 1
                                    // },
                                    filtering: true,
                                    // actionsColumnIndex: -1,
                                    addRowPosition: 'first',
                                    padding: 'dense'
                                }}
                                columns={[
                                    { title: 'Id', field: 'id', editable: 'never' },
                                    { title: 'Employee No', field: 'emp_no' },
                                    {
                                        title: 'First Name',
                                        field: 'first_name',
                                        customFilterAndSearch: (term, rowData) => customFilter(term, rowData, 'first_name'),
                                    },
                                    {
                                        title: 'Last Name',
                                        field: 'last_name',
                                        customFilterAndSearch: (term, rowData) => customFilter(term, rowData, 'last_name'),
                                    },
                                    {
                                        title: 'Job Title',
                                        field: 'job_roles',
                                        lookup: this.state.job_titles,
                                    },
                                    // { title: 'Job Title', field: 'job_title', lookup: this.state.job_titles, validate: rowData => rowData.job_title !== '', },
                                    { title: 'Email Address', field: 'email' },
                                    { title: 'Region', field: 'owner_region_name', editable: 'onAdd' },
                                    { title: 'Owner Delivery Unit', field: 'owner_delivery_name', },
                                    { title: 'Sector', field: 'sector_name', },
                                    { title: 'Market Sub Verticle', field: 'market_subvertical', lookup: this.state.market_subvertical },
                                    { title: 'Start Date', field: 'start_date', type: 'date', },
                                    { title: 'End Date', field: 'end_date', type: 'date' },
                                ]}
                                data={this.state.live}
                                isLoading={this.state.isLoading}
                                // editable={{
                                //     onRowAdd: access.l4a && access.l4a.l4a_b ? newData =>
                                //         new Promise((resolve, reject) => {
                                //             setTimeout(() => {
                                //                 this.postOps(newData)
                                //                 resolve();
                                //             }, 1000)
                                //         }) : undefined,
                                //     onRowUpdate: access.l4a && access.l4a.l4a_c ? (newData, oldData) =>
                                //         new Promise((resolve, reject) => {
                                //             setTimeout(() => {
                                //                 this.putOps(newData)
                                //                 resolve();
                                //             }, 1000)
                                //         }) : undefined,
                                // }}
                                actions={[
                                    {
                                        icon: 'add',
                                        tooltip: 'Add Employee',
                                        isFreeAction: true,
                                        disabled: !(access.l4a && access.l4a.l4a_b),
                                        onClick: (event, rowData) => {
                                            this.setState({
                                                addUser: true,
                                                type: 'add'
                                            })
                                        }
                                    },
                                    {
                                        icon: 'history_toggle_off',
                                        tooltip: 'Region Change History',
                                        onClick: (event, rowData) => {

                                            this.setState({
                                                selectedEmp: rowData
                                            }, () => { this.getRegionChangeInfo(rowData); this.getPromotionHistory(rowData) })
                                        }
                                    },
                                    {
                                        icon: 'transfer_within_a_station',
                                        tooltip: 'Region Change',
                                        onClick: (event, rowData) => {
                                            this.regionChangeOps(rowData)
                                        }
                                    },
                                    {
                                        icon: 'integration_instructions',
                                        tooltip: 'Promote Role',
                                        onClick: (event, rowData) => {
                                            rowData.role_name = rowData.job_roles
                                            this.setState({
                                                selectedEmp: rowData,
                                            }, () => this.promotionOps(rowData))
                                        }
                                    },
                                    {
                                        icon: 'edit',
                                        tooltip: 'Edit Employee',
                                        disabled: !(access.l4a && access.l4a.l4a_c),
                                        onClick: (event, rowData) => {
                                            this.setState({
                                                postData: JSON.parse(JSON.stringify(rowData)),
                                                addUser: true,
                                                type: 'edit'
                                            })
                                        }
                                    },
                                    {
                                        icon: 'date_range',
                                        tooltip: 'Assign End Date',
                                        disabled: !(access.l4a && access.l4a.l4a_c),
                                        onClick: (event, rowData) => {
                                            this.setState({
                                                postData: JSON.parse(JSON.stringify(rowData)),
                                                endDateDialog: true,
                                            })
                                        }
                                    },

                                ]}
                            /></TabPanel>
                        <TabPanel value={this.state.tabValue} index={1}>

                            <MaterialTable
                                title={<><h2 style={{ float: 'left' }}>Employees</h2>&emsp;&emsp;
                                    {access.l4a && access.l4a.l4a_d ?
                                        <React.Fragment>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                style={{ marginTop: '15px', marginLeft: '80px' }}
                                                onClick={() => this.setState({ dropZoneModal: true })}
                                            >
                                                Upload Employee Data Excel&ensp;<UploadIcon />
                                            </Button>
                                            <FormControlLabel
                                                style={{ marginLeft: '20px', marginTop: '5px' }}
                                                control={<Switch checked={this.state.global_assign} onChange={(e) => { this.setState({ global_assign: e.target.checked }) }} name="global_assign" />}
                                                label="Global Assign"
                                            />
                                        </React.Fragment> : null}
                                </>}
                                options={{
                                    // paging: false,
                                    pageSize: 20,
                                    pageSizeOptions: [20, 50, 100, 200, 500, 1000],
                                    // doubleHorizontalScroll: true,
                                    // maxBodyHeight: window.screen.height - 250,
                                    headerStyle: {
                                        fontWeight: 'bold',
                                        // color: '#005D99',
                                    },
                                    // fixedColumns: {
                                    //     left: 0,
                                    //     right: 1
                                    // },
                                    filtering: true,
                                    // actionsColumnIndex: -1,
                                    addRowPosition: 'first',
                                    padding: 'dense'
                                }}
                                columns={[
                                    { title: 'Employee No', field: 'emp_no' },
                                    {
                                        title: 'First Name',
                                        field: 'first_name',
                                        customFilterAndSearch: (term, rowData) => customFilter(term, rowData, 'first_name'),
                                    },
                                    {
                                        title: 'Last Name',
                                        field: 'last_name',
                                        customFilterAndSearch: (term, rowData) => customFilter(term, rowData, 'last_name'),
                                    },
                                    {
                                        title: 'Job Title',
                                        field: 'job_roles',
                                        lookup: this.state.job_titles,
                                    },
                                    // { title: 'Job Title', field: 'job_title', lookup: this.state.job_titles, validate: rowData => rowData.job_title !== '' },
                                    { title: 'Email Address', field: 'email' },
                                    { title: 'Region', field: 'owner_region_name', editable: 'onAdd' },
                                    { title: 'Owner Delivery Unit', field: 'owner_delivery_name', },
                                    { title: 'Sector', field: 'sector_name', },
                                    { title: 'Market Sub Verticle', field: 'market_subvertical', lookup: this.state.market_subvertical },
                                    { title: 'Start Date', field: 'start_date', type: 'date', },
                                    { title: 'End Date', field: 'end_date', type: 'date', },
                                ]}
                                data={this.state.archive}
                                isLoading={this.state.isLoading}
                                // editable={{
                                //     onRowAdd: access.l4a && access.l4a.l4a_b ? newData =>
                                //         new Promise((resolve, reject) => {
                                //             setTimeout(() => {
                                //                 this.postOps(newData)
                                //                 resolve();
                                //             }, 1000)
                                //         }) : undefined,
                                //     onRowUpdate: access.l4a && access.l4a.l4a_c ? (newData, oldData) =>
                                //         new Promise((resolve, reject) => {
                                //             setTimeout(() => {
                                //                 this.putOps(newData)
                                //                 resolve();
                                //             }, 1000)
                                //         }) : undefined,
                                // }}
                                actions={[
                                    {
                                        icon: 'add',
                                        tooltip: 'Add Employee',
                                        isFreeAction: true,
                                        disabled: !(access.l4a && access.l4a.l4a_b),
                                        onClick: (event, rowData) => {
                                            this.setState({
                                                addUser: true,
                                                type: 'add'
                                            })
                                        }
                                    },
                                    {
                                        icon: 'history_toggle_off',
                                        tooltip: 'Region Change History',
                                        onClick: (event, rowData) => {

                                            this.setState({
                                                selectedEmp: rowData
                                            }, () => { this.getRegionChangeInfo(rowData); this.getPromotionHistory(rowData) })

                                        }
                                    },
                                    {
                                        icon: 'transfer_within_a_station',
                                        tooltip: 'Region Change',
                                        onClick: (event, rowData) => {


                                            this.regionChangeOps(rowData)
                                        }
                                    },
                                    {
                                        icon: 'integration_instructions',
                                        tooltip: 'Promote Role',
                                        onClick: (event, rowData) => {
                                            this.setState({
                                                selectedEmp: rowData,

                                            }, () => this.promotionOps(rowData))

                                        }
                                    },
                                    {
                                        icon: 'edit',
                                        tooltip: 'Edit Employee',
                                        disabled: !(access.l4a && access.l4a.l4a_c),
                                        onClick: (event, rowData) => {
                                            this.setState({
                                                postData: JSON.parse(JSON.stringify(rowData)),
                                                addUser: true,
                                                type: 'edit'
                                            })
                                        }
                                    },
                                    {
                                        icon: 'date_range',
                                        tooltip: 'Assign End Date',
                                        disabled: !(access.l4a && access.l4a.l4a_c),
                                        onClick: (event, rowData) => {
                                            this.setState({
                                                postData: JSON.parse(JSON.stringify(rowData)),
                                                endDateDialog: true,
                                            })
                                        }
                                    },
                                ]}
                            /></TabPanel></Paper>

                    <Dropzone
                        open={this.state.dropZoneModal}
                        onClose={this.dropzoneClose}
                        onChange={(e) => this.fileToRead(e)}
                        preview={this.reviewModalOpen}
                    />

                    <Modal
                        open={this.state.reviewModal}
                        close={this.reviewModalClose}
                        onUpload={this.bulkUpload}
                        columns={[
                            { title: 'Employee No', field: 'emp_no' },
                            {
                                title: 'First Name',
                                field: 'first_name',
                                customFilterAndSearch: (term, rowData) => customFilter(term, rowData, 'first_name'),
                            },
                            {
                                title: 'Last Name',
                                field: 'last_name',
                                customFilterAndSearch: (term, rowData) => customFilter(term, rowData, 'last_name'),
                            },
                            {
                                title: 'Job Title',
                                field: 'job_title',
                                lookup: this.state.job_titlesID,
                            },
                            // { title: 'Job Title', field: 'job_title', lookup: this.state.job_titles, validate: rowData => rowData.job_title !== '' },
                            { title: 'Email Address', field: 'email' },
                            { title: 'Region', field: 'owner_region', editable: 'onAdd', lookup: this.state.owner_region },
                            { title: 'Owner Delivery Unit', field: 'owner_delivery_unit', lookup: this.state.owner_delivery_unit },
                            { title: 'Sector', field: 'sector', lookup: this.state.sector },
                            // { title: 'Market Sub Verticle', field: 'market_subvertical', lookup: this.state.marketSubVertical },
                            { title: 'Start Date', field: 'start_date', type: 'date', },
                            { title: 'End Date', field: 'end_date', type: 'date', },
                        ]}
                        data={this.state.dataToUpload}
                        loading={this.state.bulkUploadLoader}
                        title={"Employee excel data review"}
                    />

                    <Dialog
                        open={this.state.promotionDialog}
                        onClose={this.promotionDialogClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        maxWidth={'md'}
                    >
                        <DialogTitle id="max-width-dialog-title">
                            <Box display={'flex'} justifyContent={'space-around'} alignItems="center">
                                <Box>
                                    Promote - {this.state.selectedEmp.first_name + ' ' + this.state.selectedEmp.last_name + ' (Emp. No - ' + this.state.selectedEmp.emp_no + ')'}
                                </Box>
                                <Box marginLeft={1}>
                                    <Export selectedEmp={this.state.selectedEmp} allData={false} />
                                </Box>
                            </Box>
                        </DialogTitle>
                        <DialogContent>
                            <Box style={{ margin: 10 }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <AutcompleteInput
                                            id="role"
                                            label="Role"
                                            options={this.state.rolesArray.filter((role) => role.id !== this.state.selectedEmp.job_title)}
                                            onChange={(e, val) => {
                                                if (val) {
                                                    this.setState({ selectedRole: val }, () => {
                                                        this.setState({ nextDisabled: false })
                                                    })
                                                } else {
                                                    this.setState({ selectedRole: null }, () => {
                                                        this.setState({ nextDisabled: true })
                                                    })
                                                }
                                            }}
                                            optionLabel={option => option.job_roles}
                                            value={this.state.selectedRole}
                                            variant={'standard'}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                            <KeyboardDatePicker
                                                autoOk
                                                // clearable
                                                animateYearScrolling
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                id="promotionDate"
                                                label="Promotion Date"
                                                // maxDate={moment()}
                                                value={this.state.promotionDate}
                                                onChange={(date) => this.setState({ promotionDate: date })}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                style={{ margin: 0 }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </Grid>
                                {/* <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="promoDescription"
                                        label="Description"
                                        value={this.state.promoDescription}
                                        onChange={(e) => this.setState({ promoDescription: e.target.value })}
                                        variant="outlined"
                                        fullWidth={true}
                                        multiline
                                        rows={4}
                                    />
                                </Grid>
                            </Grid> */}
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        {/* 
                                    <MaterialTable
                                        title={'All projects of the employee'}
                                        options={{
                                            // paging: false,
                                            // pageSize: 20,
                                            // pageSizeOptions: [20, 50, 100, 200, 500, 1000],
                                            // doubleHorizontalScroll: true,
                                            // maxBodyHeight: window.screen.height - 250,
                                            headerStyle: {
                                                fontWeight: 'bold',
                                                // color: '#005D99',
                                            },
                                            // fixedColumns: {
                                            //     left: 0,
                                            //     right: 1
                                            // },
                                            // filtering: true,
                                            // actionsColumnIndex: -1,
                                            search: false,
                                            selection: true,
                                            addRowPosition: 'first',
                                            padding: 'dense'
                                        }}
                                        columns={[
                                            { title: 'CRM Id', field: 'crm_opportunity_id' },
                                            { title: 'Opportunity Name', field: 'crm_name' },
                                            { title: 'Role', field: 'role_name' },
                                        ]}
                                        data={this.state.PromtingEmpdata}
                                        onSelectionChange={(rows) => this.setState({ selectedPrps: rows })}
                                        isLoading={this.state.isLoading}
                                    // editable={{
                                    //     onRowAdd: access.l4a && access.l4a.l4a_b ? newData =>
                                    //         new Promise((resolve, reject) => {
                                    //             setTimeout(() => {
                                    //                 this.postOps(newData)
                                    //                 resolve();
                                    //             }, 1000)
                                    //         }) : undefined,
                                    //     onRowUpdate: access.l4a && access.l4a.l4a_c ? (newData, oldData) =>
                                    //         new Promise((resolve, reject) => {
                                    //             setTimeout(() => {
                                    //                 this.putOps(newData)
                                    //                 resolve();
                                    //             }, 1000)
                                    //         }) : undefined,
                                    // }}
                                    // actions={[
                                    //     {
                                    //         icon: 'history_toggle_off',
                                    //         tooltip: 'Region Change History',
                                    //         onClick: (event, rowData) => {

                                    //             this.setState({
                                    //                 selectedEmp: rowData
                                    //             })
                                    //             this.getRegionChangeInfo(rowData)
                                    //         }
                                    //     },
                                    //     {
                                    //         icon: 'transfer_within_a_station',
                                    //         tooltip: 'Region Change',
                                    //         onClick: (event, rowData) => {


                                    //             this.regionChangeOps(rowData)
                                    //         }
                                    //     },
                                    //     {
                                    //         icon: 'integration_instructions',
                                    //         tooltip: 'Promote Role',
                                    //         onClick: (event, rowData) => {
                                    //             this.promotionOps(rowData)
                                    //         }
                                    //     },

                                    // ]}
                                    /> */}
                                    </Grid>
                                </Grid>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button color='secondary' onClick={this.promotionDialogClose} >
                                Cancel
                            </Button>
                            <Button color='primary' onClick={() => this.setState({ promotionHistoryDialog: true })} >
                                History
                            </Button>
                            {/* <Button onClick={() => { if (this.state.promotionDate && this.state.selectedRole && this.state.selectedPrps.length > 0) { this.alterSchedules() } else { this.props.enqueueSnackbar('Please select promotion role, date and projects!', { variant: 'warning' }) } }} color="primary">
                                Verify Promotion changes
                            </Button> */}
                            <Button color="primary" disabled={this.state.nextDisabled} onClick={() => {
                                // this.setState({ promotionGraphDialog: true }
                                this.getUnassignedSchedules()
                            }
                            }>
                                Next
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={this.state.regionChangeDialog}
                        onClose={this.regionChangeDialogClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        maxWidth={'md'}
                    >
                        <DialogTitle id="max-width-dialog-title">
                            Region Change - {this.state.selectedEmp.first_name + ' ' + this.state.selectedEmp.last_name + ' (Emp. No - ' + this.state.selectedEmp.emp_no + ')'}
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <AutcompleteInput
                                        id="region"
                                        label="Region"
                                        options={this.state.ownerRegionArray}
                                        onChange={(e, v) => this.setState({ selectedRegion: v })}
                                        optionLabel={option => option.name}
                                        value={this.state.selectedRegion}
                                        variant={'standard'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                        <KeyboardDatePicker
                                            autoOk
                                            clearable
                                            animateYearScrolling
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="region_change__date"
                                            label="Region Change Date"
                                            // maxDate={moment()}
                                            value={this.state.regionChangeDate}
                                            onChange={(date) => this.setState({ regionChangeDate: date })}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            style={{ margin: 0 }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.regionChangeDialogClose} color="secondary">
                                Cancel
                            </Button>
                            <Button onClick={() => { this.submitRegionChange() }} color="primary">
                                Confirm
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={this.state.regionHistoryDialog}
                        onClose={this.regionHistoryDialogClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        maxWidth={'md'}
                        fullWidth={true}
                    >
                        <DialogTitle id="max-width-dialog-title">
                            {this.state.selectedEmp.first_name + ' ' + this.state.selectedEmp.last_name + ' (Emp. No - ' + this.state.selectedEmp.emp_no + ')'}
                        </DialogTitle>
                        <DialogContent>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography >{'Region Change History'}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>

                                    <MaterialTable

                                        columns={[
                                            { title: 'Region Change Date', field: 'changed_on', type: 'date' },
                                            // { title: 'From Region', field: 'old_owner_region', lookup: this.state.owner_region, },
                                            { title: 'To Region', field: 'new_owner_region', lookup: this.state.owner_region, },
                                        ]}
                                        style={{ width: '100%' }}
                                        data={this.state.regionHistory}
                                        options={{
                                            toolbar: false,
                                            paging: false,
                                            headerStyle: {
                                                fontWeight: 'bold',
                                            },
                                        }}
                                    />
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography >{'Promotion History'}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>

                                    <MaterialTable

                                        columns={[
                                            { title: 'Role', field: 'migration_taken.previousRole.role', lookup: this.state.job_titles },
                                            // { title: 'From Region', field: 'old_owner_region', lookup: this.state.owner_region, },
                                            { title: 'PromotionDate', render: (rowData) => <div>{rowData.migration_taken.promotionDate.slice(0, 10)}</div> },
                                            { title: 'Promotion Description', field: 'migration_taken.Description' },
                                            { title: 'Schedules ', render: (rowData) => <Button color='primary' onClick={() => this.setState({ projectsArray: rowData.migration_taken?.previousRole?.schdl_data }, this.setState({ promotionHistorySchedules: true }))} >Show Schedules</Button> },
                                        ]}
                                        style={{ width: '100%' }}
                                        data={this.state.promotionHistory}
                                        options={{
                                            toolbar: false,
                                            paging: false,
                                            headerStyle: {
                                                fontWeight: 'bold',
                                            },
                                        }}
                                    />
                                </AccordionDetails>
                            </Accordion>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.regionHistoryDialogClose} color="secondary">
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={this.state.verifyPromotionChanges} onClose={() => this.setState({ verifyPromotionChanges: false })} >
                        <DialogTitle><Typography variant='h5' >Verify Promotion Changes</Typography></DialogTitle>
                        <DialogContent>
                            <Grid container>
                                {this.state.verifySchedules.map((project, projectIndex) => (
                                    <React.Fragment key={projectIndex}>
                                        {
                                            project.curentCRMSchedules?.length > 0 ?
                                                <>
                                                    < h2 > {project.crm}</h2>
                                                    <Grid xs={12} item >
                                                        <h3>Promoted Role schedules</h3>
                                                        {project.newCRMSchedules?.length > 0 ?
                                                            <SchedulesContainer schedules={project.newCRMSchedules} /> : <h4 >No schedules</h4>
                                                        }     </Grid>
                                                    <Grid xs={12} item >
                                                        <h3>Previous Role schedules</h3>
                                                        {project.oldCRMSchedules?.length > 0 ? < SchedulesContainer schedules={project.oldCRMSchedules} /> : <h4>No schedules</h4>}
                                                    </Grid>
                                                    <Grid xs={12} item >
                                                        <h3>Current Role schedules</h3>
                                                        <SchedulesContainer schedules={project.curentCRMSchedules} />
                                                    </Grid>
                                                </>

                                                : null}

                                    </React.Fragment>
                                ))
                                }
                            </Grid>
                        </DialogContent >
                        <DialogActions>
                            <Button onClick={() => this.setState({ verifyPromotionChanges: false })} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={() => this.submitPromotion(this.state)}>
                                {/* <Button onClick={this.submitPromotion}> */}
                                Promote
                            </Button>
                        </DialogActions>
                    </Dialog >

                    <Dialog open={this.state.promotionHistorySchedules} onClose={() => this.setState({ promotionHistorySchedules: false })}>
                        <DialogTitle><Typography variant='h5' >Schedules</Typography></DialogTitle>

                        <DialogContent>
                            {
                                this.state.projectsArray?.map((project, projectIndex) =>
                                    <React.Fragment key={projectIndex}>
                                        {project.shdl_data.length > 0 ? <HistorySchedulesContainer crm_name={project.crm_name} schedules={project.shdl_data} /> : null}
                                    </React.Fragment>
                                )
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({ promotionHistorySchedules: false })} color="secondary">
                                Cancel
                            </Button>

                        </DialogActions>
                    </Dialog>

                    <Dialog fullWidth={true} maxWidth={'xs'} open={this.state.addUser} onClose={addDialogClose}>
                        <DialogTitle><Typography variant='h5' >{this.state.type === "add" ? "Add " : "Edit "} Employee</Typography></DialogTitle>
                        <DialogContent>
                            <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                                <TextField
                                    style={{ marginBottom: '7px' }}
                                    id="emp_no"
                                    label="Employee Number"
                                    value={this.state.postData.emp_no}
                                    onChange={e => this.handleTextChange(e)}
                                    variant="outlined"
                                    fullWidth
                                />
                                <TextField
                                    style={{ marginBottom: '7px' }}
                                    id="first_name"
                                    label="First Name"
                                    value={this.state.postData.first_name}
                                    type="name"
                                    variant="outlined"
                                    onChange={e => this.handleTextChange(e)}
                                    fullWidth
                                />
                                <TextField
                                    style={{ marginBottom: '7px' }}
                                    id="last_name"
                                    label="Last Name"
                                    value={this.state.postData.last_name}
                                    type="name"
                                    variant="outlined"
                                    onChange={e => this.handleTextChange(e)}
                                    fullWidth
                                />
                                <Autocomplete
                                    style={{ marginBottom: '7px' }}
                                    id="job_title"
                                    value={this.state.postData.job_title ? { id: this.state.postData.job_title, job_roles: this.state.job_titlesID[this.state.postData.job_title] } : null}
                                    options={this.state.rolesArray}
                                    getOptionLabel={(option) => option.job_roles}
                                    isOptionEqualToValue={(option, value) => value && option.id === value}
                                    onChange={(e, val) => this.handleSelectChange("job_title", val)}
                                    fullWidth
                                    renderInput={(params) => <TextField variant="outlined" {...params} label="Job Title" />}
                                />
                                <TextField
                                    style={{ marginBottom: '7px' }}
                                    id="email"
                                    label="Email"
                                    value={this.state.postData.email}
                                    type="name"
                                    variant="outlined"
                                    onChange={e => this.handleTextChange(e)}
                                    fullWidth
                                />
                                <Autocomplete
                                    style={{ marginBottom: '7px' }}
                                    id="owner_region"
                                    value={this.state.postData.owner_region ? { id: this.state.postData.owner_region, name: this.state.owner_region[this.state.postData.owner_region] } : null}
                                    options={this.state.ownerRegionArray}
                                    getOptionLabel={(option) => option.name}
                                    isOptionEqualToValue={(option, value) => value && option.id === value}
                                    onChange={(e, val) => this.handleSelectChange("owner_region", val)}
                                    fullWidth
                                    renderInput={(params) => <TextField variant="outlined" {...params} label="Owner Region" />}
                                />
                                <Autocomplete
                                    style={{ marginBottom: '7px' }}
                                    id="owner_delivery_unit"
                                    value={this.state.postData.owner_delivery_unit ? { id: this.state.postData.owner_delivery_unit, name: this.state.owner_delivery_unit[this.state.postData.owner_delivery_unit] } : null}
                                    options={this.state.ownerDelivery}
                                    getOptionLabel={(option) => option.name}
                                    isOptionEqualToValue={(option, value) => value && option.id === value}
                                    onChange={(e, val) => this.handleSelectChange("owner_delivery_unit", val)}
                                    fullWidth
                                    renderInput={(params) => <TextField variant="outlined" {...params} label="Owner Delivery Unit" />}
                                />
                                <Autocomplete
                                    style={{ marginBottom: '7px' }}
                                    id="sector"
                                    value={this.state.postData.sector ? { id: this.state.postData.sector, name: this.state.sector[this.state.postData.sector] } : null}
                                    options={this.state.subSector}
                                    getOptionLabel={(option) => option.name}
                                    isOptionEqualToValue={(option, value) => value && option.id === value}
                                    onChange={(e, val) => this.handleSelectChange("sector", val)}
                                    fullWidth
                                    renderInput={(params) => <TextField variant="outlined" {...params} label="Sector" />}
                                />
                                <Autocomplete
                                    style={{ marginBottom: '7px' }}
                                    id="market_subvertical"
                                    value={this.state.postData.market_subvertical ? { id: this.state.postData.market_subvertical, name: this.state.market_subvertical[this.state.postData.market_subvertical] } : null}
                                    options={this.state.marketSubVertical}
                                    getOptionLabel={(option) => option.name}
                                    isOptionEqualToValue={(option, value) => value && option.id === value}
                                    onChange={(e, val) => this.handleSelectChange("market_subvertical", val)}
                                    fullWidth
                                    renderInput={(params) => <TextField variant="outlined" {...params} label="Market Subvertical" />}
                                />
                                <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                    <KeyboardDatePicker
                                        autoOk
                                        clearable
                                        animateYearScrolling
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        id="start_date"
                                        label="Start Date"
                                        value={this.state.postData.start_date}
                                        onChange={(date) => {
                                            let postData = this.state.postData
                                            postData['start_date'] = date
                                            this.setState({ postData })
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        style={{ margin: 0 }}
                                    />
                                </MuiPickersUtilsProvider>
                                {this.state.type === "add" ?
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                        <KeyboardDatePicker
                                            autoOk
                                            clearable
                                            animateYearScrolling
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="end_date"
                                            label="End Date"
                                            value={this.state.postData.end_date}
                                            onChange={(date) => {
                                                let postData = this.state.postData
                                                postData['end_date'] = date
                                                this.setState({ postData })
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            style={{ margin: 0 }}
                                        />
                                    </MuiPickersUtilsProvider> : null}
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={addDialogClose} color="primary">
                                Cancel
                            </Button>
                            {this.state.type === 'add' ?
                                <Button disabled={this.state.addDisabled} onClick={() => this.postOps(this.state.postData)} color="primary">
                                    Add
                                </Button> :
                                <Button disabled={this.state.addDisabled} onClick={() => this.putOps(this.state.postData)} color="primary">
                                    Edit
                                </Button>}
                        </DialogActions>
                    </Dialog>
                    <Dialog open={this.state.endDateDialog} handleClose={() => this.setState({ endDateDialog: false })}>
                        <DialogTitle>Assign End Date</DialogTitle>
                        <Alert severity="warning">Changing the end date will lead to changes in the schedules for the employee</Alert>
                        <DialogContent>
                            <Box padding={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        autoOk
                                        clearable
                                        animateYearScrolling
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        id="end_date"
                                        label="End Date"
                                        value={this.state.postData.end_date}
                                        minDate={moment().subtract(7, 'days')}
                                        onChange={(date) => {
                                            let postData = this.state.postData
                                            postData['end_date'] = date
                                            this.setState({ postData })
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        style={{ margin: 0 }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({ endDateDialog: false })} color="secondary">
                                Cancel
                            </Button>
                            <Button disabled={this.state.endDateSubmitDisabled} onClick={() => { this.assignEndDate(this.state.postData) }} color="primary">
                                Submit
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={this.state.promotionHistoryDialog} onClose={() => this.setState({ promotionHistoryDialog: false })}>
                        <DialogContent>
                            <PromotionHistory emp={this.state.selectedEmp} closeDialog={this.closeDialog} />
                        </DialogContent>
                    </Dialog>

                    <Dialog maxWidth="xl" open={this.state.promotionGraphDialog} onClose={() => this.setState({ promotionGraphDialog: false })}>
                        <DialogTitle>{this.state.selectedEmp ? (this.state.selectedEmp.last_name + ", "+ this.state.selectedEmp.first_name + "'s Projects"):null}</DialogTitle>
                        <DialogContent style={{ width: "1200px" }}>
                            <Box>
                                <PromotionUnassignedSchedules
                                    unassignedSchedules={this.state.unassignedSchedules}
                                    promotionDate={this.state.promotionDate}
                                    selectedEmp={this.state.selectedEmp}
                                    selectedRole={this.state.selectedRole}
                                    closeDialog={this.closePromotionDialog}
                                />
                            </Box>
                        </DialogContent>
                        {/* <DialogActions>
                            <Button color="primary" onClick={() => this.setState({ promotionGraphDialog: false })}>Cancel</Button>
                            <Button disabled={this.state.continueDisabled} color="primary" onClick={() => {
                                if (this.continueRef.current.continueAction()) {
                                    this.continueRef.current.continueAction()
                                }
                            }}>Continue</Button>
                        </DialogActions> */}
                    </Dialog>
                </div>
            }
        </>)
    }
}

export default withSnackbar(withStyles(useStyles)(Users))