export function getPrimaryColor() {
    return '#00897b'
}

export function getSecondaryColor() {
    return '#e69e4d'
}

export function screenSizeCheck() {
    if (window.innerWidth > 1600) {
        return true
    } else {
        return false
    }
}