// The axios wrapper
import axios from "axios";

const host_name = window.location.hostname
let domain = null
let type = null

if (host_name.includes('bbparam.com')) {
  type = "prod"
  domain = 'https://rms-api-prod-gggjb0dscraqf0bc.uksouth-01.azurewebsites.net'

} else if (host_name.includes('qa.balfourbeatty.net')) {
  type = "qa"

  domain = 'https://bb-rms-api-qa.azurewebsites.net'

} else {
  domain = 'http://jayashree-dev.centralindia.cloudapp.azure.com:8001'
}

class Service {
  constructor() {
    let service = null;

    service = axios.create({
      headers: {
        // Authorization: this.getAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    // service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
    this.state = {
      statusCode: null
    };
  }

  get(path, options) {
    return this.service
      .get(domain + path, {
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }

  patch(path, options) {
    return this.service
      .request({
        method: "PATCH",
        url: domain + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }

  update(path, options) {
    return this.service
      .request({
        method: "UPDATE",
        url: domain + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }

  post(path, options) {
    return this.service
      .request({
        method: "POST",
        url: domain + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }

  put(path, options) {
    return this.service
      .request({
        method: "PUT",
        url: domain + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }

  delete(path, options) {
    return this.service
      .request({
        method: "DELETE",
        url: domain + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }
}

export default new Service();
