import React from 'react';
import moment from 'moment';
import Service from '../../../config/networkutils';
import { getCookie } from '../../../utils/CookieHelper';
import RolesEmployeeLocation from './RolesEmployeeLocation';
import { LoopCircleLoading, RotateCircleLoading } from 'react-loadingg';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Slide } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import {
    weekStartDates,
} from "../../../utils/betweenDates";
import { getCRMTableData, getEmployeeRegionWithHistory, getEmployeesLeaves, getOtherTasks, } from '../../../config/PromisesHandler';
import { DatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';


import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import UserAllocation from '../../UserAllocation/UserAllocation';
import CloseIcon from '@material-ui/icons/Close';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const months = { 0: 'January', 1: 'Febrauary', 2: 'March', 3: 'April', 4: 'May', 5: 'June', 6: 'July', 7: 'August', 8: 'September', 9: 'October', 10: 'November', 11: 'December' }
const token = getCookie("ptd_ts_token");
const user = JSON.parse(getCookie("ptd_ts_user"));
class ResourceLocation extends React.Component {
    constructor() {
        super()
        this.state = {
            allRoles: [],
            rolesObj: {},
            allEmp: [],
            allEmpRegionHistory: [],
            regionObj: {},
            isLoading: true,
            isLoading1: false,
            allProjects: [],
            responseData: [],
            projectObj: {},
            selectedRole: [],
            rolesData: [],
            searchRerenders: false,
            allSubSectors: [],
            selectedSubSector: [],
            allOwnerRegions: [],
            selectedOwnerRegion: [],
            allOwnerDeliveryUnits: [],
            selectedOwnerDelivery: [],
            showRole: false,
            showRoleIndex: null,
            selectedCrm: { name: '', id: '' },
            selectedEmployee: { first_name: 'Select Employee', last_name: '', id: '' },
            splittedRoleEmployees: [],
            selectedStartDate: moment().subtract(2, 'months').startOf('month'),
            selectedEndDate: moment().add(6, 'months').endOf('month'),
            allocationDialog: false,
            allocationDialogSize: 'xs',
            allocationData: null,
            toggleView: 'role',
        }
        this.handleRefresh = this.handleRefresh.bind(this);
        this.getDialogSize = this.getDialogSize.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.addAllocation = this.addAllocation.bind(this);
    }

    editedScheduleUIUpdate = (empArray) => {
        // Filter the response data based on the employee
        let allRoleEmployeess = [...this.state.responseData];
        for (let crmIndex = 0; crmIndex < allRoleEmployeess.length; crmIndex++) {
            for (let empIndex = 0; empIndex < empArray.length; empIndex++) {
                if ((allRoleEmployeess[crmIndex].crm === empArray[empIndex].crm) && (allRoleEmployeess[crmIndex].emp_id === empArray[empIndex].emp_id)) {
                    allRoleEmployeess[crmIndex] = empArray[empIndex];
                }
            }
        }
        this.setState({
            ...this.state,
            allRoleEmployees: allRoleEmployeess,
        });
    };

    getProjectsEmployees() {
        this.setState({ isLoading: true, isLoading1: true })
        let selectedRolesIds = [];
        this.state.selectedRole.forEach((role) => {
            selectedRolesIds.push(role.id);
        });

        let roles_crm_emp_id = '/api/prp/rolenew/get_staff_location_data/?archive_reason=0&'
        if (selectedRolesIds.length > 0) {
            roles_crm_emp_id = roles_crm_emp_id + `role=${selectedRolesIds.join()}&`
        }
        if (this.state.selectedCrm?.id) {
            roles_crm_emp_id = roles_crm_emp_id + `crm=${this.state.selectedCrm.id}&`
        }
        if (this.state.selectedEmployee?.id) {
            roles_crm_emp_id = roles_crm_emp_id + `emp=${this.state.selectedEmployee.id}`
        }

        if (this.state.selectedStartDate && this.state.selectedEndDate) {
            roles_crm_emp_id = roles_crm_emp_id + `&from_date=${moment(this.state.selectedStartDate).format('YYYY-MM-DD')}&to_date=${moment(this.state.selectedEndDate).format('YYYY-MM-DD')}`
        }
        // if (roles_crm_emp_id === roles_crm_emp + '?is_archive=0&') {
        //     roles_crm_emp_id = roles_crm_emp + '?is_archive=0'
        // }
        // roles_crm_emp_id = roles_crm_emp + `&archive_reason=1`
        Service.get(roles_crm_emp_id, {
            headers: { Authorization: "Token " + token, }
        })
            .then(res => {
                let rolesss;
                if (this.state.selectedRole.length === 0) {
                    rolesss = [...this.state.allRoles]
                } else {
                    rolesss = [...this.state.selectedRole]

                }
                this.setState({ reportedRoles: rolesss }, () => this.makeReport(res.data))
                this.setState({ responseData: res.data, isLoading: false, isLoading1: false })
            }).catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' })
                this.setState({ isLoading: false, isLoading1: false })
            })
    }

    getDropdownData() {

        return new Promise((resolve, reject) => {
            Service.get('/api/roles/get_default_data/', {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => reject(error))
        })

    }

    componentDidMount() {
        Promise.all([
            this.getDropdownData(),
            getEmployeeRegionWithHistory(),
            getCRMTableData(),
            getOtherTasks(),
            getEmployeesLeaves(),
        ])
            .then(res => {
                let data = res[0].roles

                let rolesobj = {}
                data.forEach(ele => {
                    let newData = { [ele.id]: ele.job_roles }
                    rolesobj = { ...rolesobj, ...newData }
                })

                let regionObj = {}
                res[0].owner_region_sector.forEach(ele => {
                    let newData = { [ele.id]: ele.name }
                    regionObj = { ...regionObj, ...newData }
                })

                // let liveOpportunities = []
                // res[4].forEach(project => {
                //     if (project.archive_reason === 0 && project.is_trashed === false) {
                //         liveOpportunities.push(project)
                //     }
                // })

                let projectObj = {}
                res[0].crm.forEach(ele => {
                    let newData = { [ele.id]: { project_code: ele.project_code, name: ele.name } }
                    projectObj = { ...projectObj, ...newData }
                })

                let othertaskObject = {}
                res[3].forEach(ele => {
                    let newData = { [ele.id]: ele.name }
                    othertaskObject = { ...othertaskObject, ...newData }
                })

                this.setState({
                    rolesObj: rolesobj,
                    allRoles: res[0].roles,
                    allEmp: res[0].employees,
                    allEmpRegionHistory: res[1],
                    allOwnerRegions: res[0].owner_region_sector,
                    regionObj: regionObj,
                    allProjects: res[0].crm,
                    projectObj: projectObj,
                    isLoading: false,
                    completedOppertunities: res[2],
                    allSubSectors: res[0].sub_sector,
                    allOwnerDeliveryUnits: res[0].owner_delivery,
                    // allMarketSubVerticles:3,
                    // rolesData:res[0]
                    allOtherTasks: res[3],
                    allEmployeesLeaves: res[4],
                    othertaskObject,
                    liveOpportunities: res[0].crm,
                }, () => {

                    let searchParams = new URLSearchParams(window.location.search)
                    let selectedRoles = []
                    let selectedRegions = []
                    let selectedSubSectors = []
                    let selectedOwnerDeliverys = []
                    let selectedCrm = []
                    let selectedEmployee = []

                    if (searchParams.get("role")) {
                        let idNum = searchParams.get("role").split(',')
                        for (let id = 0; id < idNum.length; id++) {
                            let perRole = this.state.allRoles.filter((rol) => rol.id === parseInt(idNum[id]))
                            selectedRoles.push(perRole[0])
                        }
                    }
                    if (searchParams.get("selectedOwnerRegion")) {
                        let idNum = searchParams.get("selectedOwnerRegion").split(',')
                        for (let id = 0; id < idNum.length; id++) {
                            let perRegion = this.state.allOwnerRegions.filter((regio) => regio.id === parseInt(idNum[id]))
                            selectedRegions.push(perRegion[0])
                        }
                    }
                    if (searchParams.get("selectedSubSector")) {
                        let idNum = searchParams.get("selectedSubSector").split(',')
                        for (let id = 0; id < idNum.length; id++) {
                            let perSector = this.state.allSubSectors.filter((secto) => secto.id === parseInt(idNum[id]))
                            selectedSubSectors.push(perSector[0])
                        }
                    }
                    if (searchParams.get("selectedOwnerDelivery")) {
                        let idNum = searchParams.get("selectedOwnerDelivery").split(',')
                        for (let id = 0; id < idNum.length; id++) {
                            let perODU = this.state.allOwnerDeliveryUnits.filter(odu => odu.id === parseInt(idNum[id]))
                            selectedOwnerDeliverys.push(perODU[0])
                        }
                    }
                    if (searchParams.get("employee")) {
                        let idNum = searchParams.get("employee")
                        selectedEmployee = this.state.allEmp.filter(emp => emp.id === parseInt(idNum))[0]
                    } else {
                        selectedEmployee = { first_name: 'Select Employee', last_name: '', id: '' }
                    }
                    if (searchParams.get("crm")) {
                        let idNum = searchParams.get("crm")
                        selectedCrm = this.state.allProjects.filter(crm => crm.id === parseInt(idNum))[0]
                    }
                    if (selectedCrm.name || selectedEmployee.id) {
                        this.setState({
                            selectedRole: selectedRoles, selectedCrm, selectedEmployee, isLoading: true, toggleView: 'crm'
                        }, () => this.getProjectsEmployees())
                    } else if (selectedRoles.length > 0 ||
                        selectedRegions.length > 0 ||
                        selectedSubSectors.length > 0 ||
                        selectedOwnerDeliverys.length > 0) {
                        this.setState({
                            selectedRole: selectedRoles, selectedOwnerRegion: selectedRegions
                            , selectedSubSector: selectedSubSectors, selectedOwnerDelivery: selectedOwnerDeliverys, toggleView: 'role'
                        }, () => this.fetchOps())
                    }
                })
            })
            .catch(e => {
                this.props.enqueueSnackbar('Something went wrong. Please try again later.', {
                    variant: 'error'
                });
            })

    }

    handleRefresh() {
        this.componentDidMount()
    }

    makeReport(allRoles) {

        let reportedRoles = []
        let templateRoles = []
        let splittedRoleEmployees = []
        let finalColumns = []
        let allMonths = []

        for (let index = 0; index < allRoles.length; index++) {
            const element = allRoles[index];

            const startOfMonth = this.state.selectedStartDate ? moment(this.state.selectedStartDate).startOf('month') : moment().subtract(2, 'months').startOf('month');
            const endOfMonth = this.state.selectedEndDate ? moment(this.state.selectedEndDate).endOf('month') : moment().add(6, 'months').endOf('month');

            let startDate = moment(startOfMonth)
            let endDate = moment(endOfMonth)

            let result = [];

            if (endDate.isBefore(startDate)) {
                throw new Error("End date must be greater than start date.")
            }

            while (startDate.isBefore(endDate)) {
                result.push(months[startDate.month()] + ' ' + startDate.year());
                startDate.add(1, 'month');
            }

            let allWeekDates = weekStartDates(startOfMonth, endOfMonth);

            let final = []
            let colspan = 1;
            for (let i = 0; i < allWeekDates.length; i++) {

                let date = moment(allWeekDates[i], 'DD/MM/YYYY')
                let monthYear = months[date.month()] + ' ' + date.year()
                if (!result.length) {
                    result.push(monthYear)
                    final.push({ month: monthYear, colSpan: colspan })
                }
                else {
                    if (result[result.length - 1] === monthYear) {
                        colspan += 1
                        final[final.length - 1]['colSpan'] = colspan
                    }
                    else {
                        colspan = 1
                        result.push(monthYear)
                        final.push({ month: monthYear, colSpan: colspan })
                    }
                }

            }
            allMonths = final

            reportedRoles.push(this.state.reportedRoles.filter((ele) => ele.id === element.role_id)[0])

            let weeks = [];
            let weekdata = [];
            for (let i = 0; i < element.project_details.length; i++) {
                let roleItem = element.project_details[i];
                for (let m = 0; m < allWeekDates.length; m++) {
                    let date = allWeekDates[m];
                    if (roleItem.dates.hasOwnProperty(date) && roleItem.dates[date] !== 0) {
                        if (weeks.indexOf(date) === -1) {
                            weeks.push(date);
                            weekdata.push({ name: [roleItem.crm_name], project_code: [[roleItem.crm_id]], crmId: [roleItem.crm], crm_color_code: [roleItem.crm_color_code], })
                        } else {
                            if (weekdata[weeks.indexOf(date)]['name'] !== "") {
                                weekdata[weeks.indexOf(date)]['name'].push(roleItem.crm_name)
                                weekdata[weeks.indexOf(date)]['project_code'].push(roleItem.crm_id)
                                weekdata[weeks.indexOf(date)]['crmId'].push(roleItem.crm_id)
                                weekdata[weeks.indexOf(date)]['crm_color_code'].push(roleItem.crm_color_code)
                            } else if (weekdata[weeks.indexOf(date)]['name'] === "") {
                                weekdata[weeks.indexOf(date)]['name'].push(this.state.projectObj[roleItem.crm_name])
                                weekdata[weeks.indexOf(date)]['project_code'].push(roleItem.crm_id)
                                weekdata[weeks.indexOf(date)]['crmId'].push(roleItem.crm_id)
                                weekdata[weeks.indexOf(date)]['crm_color_code'].push(roleItem.crm_color_code)
                            }
                        }
                    }
                    else {
                        weeks.push(date);
                        weekdata.push({ name: [], crmId: [], project_code: [], crm_color_code: [] })
                    }
                }
            }
            element['weeks'] = weeks;
            element['weekdata'] = weekdata
            if (element.weekdata.length > 0) {
                if ((!element.end_date ? true : moment(element.end_date).isAfter(moment().format()))) {
                    // filterEmp.push(element)
                }
            }

            let columns = []
            for (let i = 0; i < allWeekDates.length; i++) {
                let newObj = {
                    title: moment(allWeekDates[i], 'DD/MM/YYYY').format('DD MMM'),
                    field: allWeekDates[i],
                    type: 'number',
                    width: 150,
                    sorting: false,
                    editable: 'never',
                    filtering: false
                }
                columns.push(newObj)
            }
            finalColumns = columns

        }
        allRoles.sort((a, b) => b.role_name - a.role_name);
        splittedRoleEmployees.push({ role: 0, allEmpCopy: allRoles, allEmp: allRoles, columns: finalColumns, allMonths: allMonths })
        this.setState({ splittedRoleEmployees, reportedRoles, templateRoles })
    }

    fetchOps() {
        this.setState({ isLoading1: true })

        let selectedRolesIds = [];
        this.state.selectedRole.forEach((role) => {
            selectedRolesIds.push(role.id);
        });

        let selectedSubSectorsIds = [];
        this.state.selectedSubSector.forEach((sector) => {
            selectedSubSectorsIds.push(sector.id);
        });

        let selectedOwnerRegionIds = [];
        this.state.selectedOwnerRegion.forEach((region) => {
            selectedOwnerRegionIds.push(region.id);
        });

        let selectedOwnerDeliveryIds = [];
        this.state.selectedOwnerDelivery.forEach((odu) => {
            selectedOwnerDeliveryIds.push(odu.id);
        });
        let roles_sectors_regions_OD_uri = "/api/prp/rolenew/get_staff_location_data/?archive_reason=0&";
        if (selectedRolesIds.length > 0) {
            roles_sectors_regions_OD_uri = roles_sectors_regions_OD_uri + `role=${selectedRolesIds.join()}&`
        }
        if (selectedSubSectorsIds.length > 0) {
            roles_sectors_regions_OD_uri = roles_sectors_regions_OD_uri + `sector=${selectedSubSectorsIds.join()}&`
        }
        if (selectedOwnerRegionIds.length > 0) {
            roles_sectors_regions_OD_uri = roles_sectors_regions_OD_uri + `region=${selectedOwnerRegionIds.join()}&`
        }
        if (selectedOwnerDeliveryIds.length > 0) {
            roles_sectors_regions_OD_uri = roles_sectors_regions_OD_uri + `delivery=${selectedOwnerDeliveryIds.join()}&`
        }
        if (this.state.selectedEmployee) {
            roles_sectors_regions_OD_uri = roles_sectors_regions_OD_uri + `emp=${this.state.selectedEmployee.id}`
        }
        if (roles_sectors_regions_OD_uri[roles_sectors_regions_OD_uri.length - 1] === "&") {
            roles_sectors_regions_OD_uri = roles_sectors_regions_OD_uri.substring(0, roles_sectors_regions_OD_uri.length - 1)
        }

        if (this.state.selectedStartDate && this.state.selectedEndDate) {
            roles_sectors_regions_OD_uri = roles_sectors_regions_OD_uri + `&from_date=${moment(this.state.selectedStartDate).format('YYYY-MM-DD')}&to_date=${moment(this.state.selectedEndDate).format('YYYY-MM-DD')}`
        }

        Service.get(roles_sectors_regions_OD_uri, {
            headers: {
                Authorization: "Token " + token,
            }
        })
            .then(res => {
                let rolesss;
                if (this.state.selectedRole.length === 0) {
                    rolesss = [...this.state.allRoles]
                } else {
                    rolesss = [...this.state.selectedRole]

                }
                this.setState({ reportedRoles: rolesss }, () => this.makeReport(res.data))
                this.setState({ responseData: res.data, isLoading: false, isLoading1: false })
            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                this.setState({ isLoading: false, isLoading1: false })
            })
    }

    exportExcel(filter) {
        let roles_sectors_regions_OD_uri = "/api/prp/rolenew/get_staff_location_data/?archive_reason=0&get_report=true&";
        if (filter === 'crm') {
            if (this.state.selectedEmployee) {
                if (this.state.selectedEmployee.first_name !== 'Select Employee') {
                    roles_sectors_regions_OD_uri = roles_sectors_regions_OD_uri + `emp=${this.state.selectedEmployee.id}&`
                }
            }
            if (this.state.selectedCrm) {
                if (this.state.selectedCrm.id) {
                    roles_sectors_regions_OD_uri = roles_sectors_regions_OD_uri + `crm=${this.state.selectedCrm.id}&`
                }
            }

        } else {
            let selectedRolesIds = [];
            this.state.selectedRole.forEach((role) => {
                selectedRolesIds.push(role.id);
            });

            let selectedSubSectorsIds = [];
            this.state.selectedSubSector.forEach((sector) => {
                selectedSubSectorsIds.push(sector.id);
            });

            let selectedOwnerRegionIds = [];
            this.state.selectedOwnerRegion.forEach((region) => {
                selectedOwnerRegionIds.push(region.id);
            });

            let selectedOwnerDeliveryIds = [];
            this.state.selectedOwnerDelivery.forEach((odu) => {
                selectedOwnerDeliveryIds.push(odu.id);
            });
            if (selectedRolesIds.length > 0) {
                roles_sectors_regions_OD_uri = roles_sectors_regions_OD_uri + `role=${selectedRolesIds.join()}&`
            }
            if (selectedSubSectorsIds.length > 0) {
                roles_sectors_regions_OD_uri = roles_sectors_regions_OD_uri + `sector=${selectedSubSectorsIds.join()}&`
            }
            if (selectedOwnerRegionIds.length > 0) {
                roles_sectors_regions_OD_uri = roles_sectors_regions_OD_uri + `region=${selectedOwnerRegionIds.join()}&`
            }
            if (selectedOwnerDeliveryIds.length > 0) {
                roles_sectors_regions_OD_uri = roles_sectors_regions_OD_uri + `delivery=${selectedOwnerDeliveryIds.join()}&`
            }
        }


        if (roles_sectors_regions_OD_uri[roles_sectors_regions_OD_uri.length - 1] === "&") {
            roles_sectors_regions_OD_uri = roles_sectors_regions_OD_uri.substring(0, roles_sectors_regions_OD_uri.length - 1)
        }

        if (this.state.selectedStartDate && this.state.selectedEndDate) {
            roles_sectors_regions_OD_uri = roles_sectors_regions_OD_uri + `&from_date=${moment(this.state.selectedStartDate).format('YYYY-MM-DD')}&to_date=${moment(this.state.selectedEndDate).format('YYYY-MM-DD')}`
        }

        Service.get(roles_sectors_regions_OD_uri, {
            headers: {
                Authorization: "Token " + token,
            }
        })
            .then(res => {
                this.setState({
                    excelExportDialog: true
                })
            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                this.setState({ isLoading: false, isLoading1: false })
            })
    }

    filterCRM() {
        if (this.state.selectedRole.length > 0 || this.state.selectedOwnerRegion.length > 0 || this.state.selectedSubSector.length > 0 || this.state.selectedOwnerDelivery.length > 0) {
            this.fetchOps()
        } else {
            this.props.enqueueSnackbar('Please select atleast one of the filters!', { variant: 'warning' })
        }
    }

    reRenderingEmpLocation() {
        this.setState({ searchRerenders: true });
    }

    handleOwnerDeliveryChange = (e, value) => {
        let searchParams = new URLSearchParams(window.location.search)
        if (value.length > 0) {
            let selectedOwnerDeliveryIds = [];
            value.forEach((od) => {
                selectedOwnerDeliveryIds.push(od.id)
            })
            if (searchParams.has("selectedOwnerDelivery")) {
                searchParams.set("selectedOwnerDelivery", selectedOwnerDeliveryIds.join(","))
            } else {
                searchParams.append("selectedOwnerDelivery", selectedOwnerDeliveryIds.join(","))
            }

        } else if (value.length === 0) {
            searchParams.delete("selectedOwnerDelivery")

        }
        // sessionStorage.setItem("lasturi", url + '?' + searchParams.toString())
        // window.history.pushState({ id: window.location.href }, '', url + '?' + searchParams.toString())
        let hash = window.location.hash
        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

        window.history.pushState(null, '', newRelativePathQuery);

        this.setState({ selectedOwnerDelivery: value })
    }

    handleSubSectorChange = (e, value) => {
        let searchParams = new URLSearchParams(window.location.search)
        if (value.length > 0) {
            let selectedSubSectorIds = [];
            value.forEach((subSector) => {
                selectedSubSectorIds.push(subSector.id)
            })
            if (searchParams.has("selectedSubSector")) {
                searchParams.set("selectedSubSector", selectedSubSectorIds.join(","))
            } else {
                searchParams.append("selectedSubSector", selectedSubSectorIds.join(","))
            }

        } else if (value.length === 0) {
            searchParams.delete("selectedSubSector")

        }
        // sessionStorage.setItem("lasturi", url + '?' + searchParams.toString())
        // window.history.pushState({ id: window.location.href }, '', url + '?' + searchParams.toString())
        let hash = window.location.hash
        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

        window.history.pushState(null, '', newRelativePathQuery);


        this.setState({ selectedSubSector: value })
    }

    handleRoleChange = (e, value) => {
        let searchParams = new URLSearchParams(window.location.search)
        if (value.length > 0) {
            let selectedRolesIds = [];
            value.forEach((region) => {
                selectedRolesIds.push(region.id)
            })
            if (searchParams.has("role")) {
                searchParams.set("role", selectedRolesIds.join(","))
            } else {
                searchParams.append("role", selectedRolesIds.join(","))
            }

        } else if (value.length === 0) {
            searchParams.delete("role")

        }
        // sessionStorage.setItem("lasturi", url + '?' + searchParams.toString())
        // window.history.pushState({ id: window.location.href }, '', url + '?' + searchParams.toString())
        let hash = window.location.hash
        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

        window.history.pushState(null, '', newRelativePathQuery);

        this.setState({ selectedRole: value, reportedRoles: value })
    }

    handleCrmChange(e, value) {
        let searchParams = new URLSearchParams(window.location.search)
        if (value) {
            // let selectedCrmIds = [];
            // value.forEach((crm) => {
            //     selectedCrmIds.push(crm.id)
            // })
            if (searchParams.has("crm")) {
                searchParams.set("crm", value.id)
            } else {
                searchParams.append("crm", value.id)
            }

        } else {

            searchParams.delete("crm")
        }

        // sessionStorage.setItem("lasturi", url + '?' + searchParams.toString())
        // window.history.pushState({ id: window.location.href }, '', url + '?' + searchParams.toString())
        let hash = window.location.hash
        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

        window.history.pushState(null, '', newRelativePathQuery);

        this.setState({ selectedCrm: value })
    }

    handleEmployeeChange(e, value) {
        let searchParams = new URLSearchParams(window.location.search)
        if (value) {
            // let selectedCrmIds = [];
            // value.forEach((crm) => {
            //     selectedCrmIds.push(crm.id)
            // })
            if (searchParams.has("employee")) {
                searchParams.set("employee", value.id)
            } else {
                searchParams.append("employee", value.id)
            }

        } else {

            searchParams.delete("employee")
        }

        // sessionStorage.setItem("lasturi", url + '?' + searchParams.toString())
        // window.history.pushState({ id: window.location.href }, '', url + '?' + searchParams.toString())
        let hash = window.location.hash
        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

        window.history.pushState(null, '', newRelativePathQuery);

        this.setState({ selectedEmployee: value })

    }

    handleRegionChange = (e, value) => {
        let searchParams = new URLSearchParams(window.location.search)
        if (value.length > 0) {
            let selectedRegionIds = [];
            value.forEach((region) => {
                selectedRegionIds.push(region.id)
            })
            if (searchParams.has("selectedOwnerRegion")) {
                searchParams.set("selectedOwnerRegion", selectedRegionIds.join(","))
            } else {
                searchParams.append("selectedOwnerRegion", selectedRegionIds.join(","))
            }

        } else if (value.length === 0) {
            searchParams.delete("selectedOwnerRegion")

        }

        // sessionStorage.setItem("lasturi", url + '?' + searchParams.toString())
        // window.history.pushState({ id: window.location.href }, '', url + '?' + searchParams.toString())

        let hash = window.location.hash
        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

        window.history.pushState(null, '', newRelativePathQuery);


        this.setState({ selectedOwnerRegion: value })
    }

    openRole = (roleIndex) => { this.setState({ showRole: (!this.state.showRole), showRoleIndex: roleIndex }) }

    getReport() {
        this.setState({ isLoading1: true }, () => {
            this.setState({ isLoading1: false }, () => {
                if (this.state.selectedCrm?.id || this.state.selectedEmployee?.id || this.state.selectedRole?.length > 0) {
                    this.setState({ isLoading: true, splittedRoleEmployees: [] }, () => {
                        this.getProjectsEmployees()
                    })
                } else if (this.state.selectedRole.length > 0 || this.state.selectedOwnerRegion.length > 0 || this.state.selectedSubSector.length > 0 || this.state.selectedOwnerDelivery.length > 0) {
                    this.filterCRM()
                } else {
                    this.fetchOps()
                }
            })
        })
    }

    handleDateChange1 = (d) => {
        this.setState({ selectedStartDate: d })
    }

    handleDateChange2 = (d) => {
        this.setState({ selectedEndDate: d })
    }

    prev6months = () => {
        if (!this.state.selectedStartDate || !this.state.selectedEndDate) {
            this.setState({ selectedStartDate: moment().subtract(2, 'months').startOf('month'), selectedEndDate: moment().add(6, 'months').endOf('month') }, () => {
                this.setState({ selectedStartDate: moment(this.state.selectedStartDate).subtract(6, 'months'), selectedEndDate: moment(this.state.selectedEndDate).subtract(6, 'months') }, () => {
                    this.getReports()
                })
            })
        } else {
            this.setState({ selectedStartDate: moment(this.state.selectedStartDate).subtract(6, 'months'), selectedEndDate: moment(this.state.selectedEndDate).subtract(6, 'months') }, () => {
                this.getReports()
            })
        }

    }

    next6months = () => {
        if (!this.state.selectedStartDate || !this.state.selectedEndDate) {
            this.setState({ selectedStartDate: moment().subtract(2, 'months').startOf('month'), selectedEndDate: moment().add(6, 'months').endOf('month') }, () => {
                this.setState({ selectedStartDate: moment(this.state.selectedStartDate).add(6, 'months'), selectedEndDate: moment(this.state.selectedEndDate).add(6, 'months') }, () => {
                    this.getReports(this.state.selectedStartDate, this.state.selectedEndDate)
                })
            })
        } else {
            this.setState({ selectedStartDate: moment(this.state.selectedStartDate).add(6, 'months'), selectedEndDate: moment(this.state.selectedEndDate).add(6, 'months') }, () => {
                this.getReports(this.state.selectedStartDate, this.state.selectedEndDate)
            })
        }
    }

    getReports() {
        if (!this.state.selectedEndDate || !this.state.selectedStartDate) {
            this.props.enqueueSnackbar("Please select the months!", {
                variant: "warning",
            }
            );
        } else {
            this.getReport()
        }
    }

    getDialogSize = (size) => {
        this.setState({ allocationDialogSize: size })
    }

    closeDialog = () => {
        this.setState({ allocationDialog: false, allocationDialogSize: "xs", allocationData: null }, () => {
            if (this.state.selectedCrm?.id || this.state.selectedEmployee?.id || this.state.selectedRole?.length > 0) {
                this.setState({ isLoading: true, splittedRoleEmployees: [] }, () => {
                    this.getProjectsEmployees()
                })
            } else if (this.state.selectedRole.length > 0 || this.state.selectedOwnerRegion.length > 0 || this.state.selectedSubSector.length > 0 || this.state.selectedOwnerDelivery.length > 0) {
                this.filterCRM()
            } else {
                this.fetchOps()
            }
        })
    }

    addAllocation = (emp, prj) => {
        let allocationData = { emp: emp, prj: prj }
        this.setState({ allocationData: allocationData }, () => {
            this.setState({ allocationDialog: true, allocationDialogSize: "xs" })
        })
    }



    render() {
        // const classes = this.props.classes;

        const handleClose = () => {
            this.setState({ allocationDialog: false, allocationDialogSize: "xs", allocationData: null })
        };

        const handleToggle = (e, newValue) => {
            if (newValue !== null) {
                this.setState({ toggleView: newValue })
            }
        };

        return (
            <div>
                {this.state.isLoading ? <RotateCircleLoading color="#005D99" /> :
                    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>

                        <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} mb={'10px'} width={'100%'}>
                            <ToggleButtonGroup
                                color="primary"
                                size="small"
                                value={this.state.toggleView}
                                onChange={handleToggle}
                                exclusive
                                aria-label="text formatting"
                            >
                                <ToggleButton value={"role"} aria-label="to_lead">
                                    Sector/Region/Owner Delivery Unit/Role
                                </ToggleButton>
                                <ToggleButton value={"crm"} aria-label="to_em">
                                    CRM/Employee
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Box>

                        {this.state.toggleView === 'role' ?
                            <Grid container justifyContent="center" spacing={1} style={{ marginBottom: '20px' }}>
                                <Grid item xs={2}>
                                    <Autocomplete
                                        multiple
                                        id="sub-sector"
                                        options={this.state.allSubSectors}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.name}
                                        onChange={(e, value) => this.handleSubSectorChange(e, value)}
                                        value={this.state.selectedSubSector}
                                        size="small"
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.name}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Sector" placeholder="Sector" />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={2}>
                                    <Autocomplete
                                        multiple
                                        id="owner-region-sector"
                                        options={this.state.allOwnerRegions}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.name}
                                        onChange={(e, value) => this.handleRegionChange('selectedOwnerRegion', value)}
                                        value={this.state.selectedOwnerRegion}
                                        size="small"
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.name}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Region" placeholder="Region" />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <Autocomplete
                                        multiple
                                        id="owner-delivery-unit"
                                        options={this.state.allOwnerDeliveryUnits}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.name}
                                        onChange={(e, value) => this.handleOwnerDeliveryChange(e, value)}
                                        value={this.state.selectedOwnerDelivery}
                                        size="small"
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.name}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Owner Delivery Unit" placeholder="Owner Delivery Unit" />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <Autocomplete
                                        multiple
                                        id="roles"
                                        options={this.state.allRoles}
                                        getOptionLabel={(option) => option.job_roles}
                                        onChange={(e, value) => this.handleRoleChange(e, value)}
                                        disableCloseOnSelect
                                        value={this.state.selectedRole}
                                        size="small"
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.job_roles}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Role" placeholder="Role" />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={2}>
                                    <Button variant="contained" color="primary" onClick={() => {
                                        this.setState({ showRole: false, showRoleIndex: null, splittedRoleEmployees: [] })
                                        this.filterCRM()
                                    }
                                    }> Get Report</Button>
                                    <Button variant="contained" style={{marginLeft:"5px"}} color="primary" onClick={() => { this.exportExcel('role') }}>
                                        Excel Export
                                    </Button>
                                </Grid>
                            </Grid> :
                            <Grid container justifyContent="center" spacing={2} style={{ marginBottom: '20px' }}>
                                <Grid item xs={3}>
                                    <Autocomplete
                                        // multiple
                                        id="Crm"
                                        options={this.state.allProjects}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.name}
                                        onChange={(e, value) => this.handleCrmChange(e, value)}
                                        value={this.state.selectedCrm}
                                        size="small"
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.name}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (

                                            <TextField {...params} variant="outlined" label="Crm" placeholder="Crm" />
                                        )}
                                    />

                                </Grid>
                                <Grid item xs={3}>
                                    <Autocomplete
                                        // multiple
                                        id="Employee"
                                        options={this.state.allEmp}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.first_name + ' ' + option.last_name}
                                        onChange={(e, value) => this.handleEmployeeChange(e, value)}
                                        value={this.state.selectedEmployee}
                                        size="small"
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.first_name + ' ' + option.last_name}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (

                                            <TextField {...params} variant="outlined" label="Employee" placeholder="Employee" />
                                        )}
                                    />

                                </Grid>

                                <Grid item xs={3}>
                                    <Button variant="contained" color="primary" onClick={() => {
                                        // this.setState({ showRole: false, showRoleIndex: null })
                                        if (this.state.selectedCrm?.id || this.state.selectedEmployee?.id || this.state.selectedRole?.length > 0) {
                                            this.setState({ isLoading: true, splittedRoleEmployees: [] }, () => {
                                                this.getProjectsEmployees()
                                            })
                                        } else {
                                            this.props.enqueueSnackbar('Please select atleast one of the filters!', { variant: 'warning' })
                                        }
                                    }}> Get Report</Button>
                                    <Button variant="contained" style={{marginLeft:"5px"}} color="primary" onClick={() => { this.exportExcel('crm') }}>
                                        Excel Export 
                                    </Button>
                                </Grid>
                            </Grid>}

                        {this.state.splittedRoleEmployees.length > 0 ?
                            <Box display='flex' flexDirection={'row'} mb={1}>
                                <Box flexGrow={1}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            variant="dialog"
                                            openTo="month"
                                            views={["month", "year"]}
                                            label="Start Month"
                                            maxDate={this.state.selectedEndDate ? moment(this.state.selectedEndDate).subtract(1, 'month') : undefined}
                                            autoOk={true}
                                            value={this.state.selectedStartDate}
                                            onChange={this.handleDateChange1}
                                            style={{ marginRight: '10px' }}
                                        />
                                        <DatePicker
                                            variant="dialog"
                                            openTo="month"
                                            views={["month", "year"]}
                                            label="End Month"
                                            minDate={this.state.selectedStartDate ? moment(this.state.selectedStartDate).add(1, 'month') : undefined}
                                            autoOk={true}
                                            value={this.state.selectedEndDate}
                                            onChange={this.handleDateChange2}
                                            style={{ marginRight: '10px' }}
                                        />
                                    </MuiPickersUtilsProvider>
                                    <Button variant="contained" color="primary"
                                        onClick={() => {
                                            this.getReports()
                                        }}> Get Report</Button>
                                </Box>
                                {/* <Box display={'flex'} flexDirection={'row'} margin={'dense'}> */}
                                <Button
                                    component="label"
                                    variant="outlined"
                                    tabIndex={-1}
                                    color="primary"
                                    startIcon={<PersonAddAlt1Icon />}
                                    onClick={() => this.setState({ allocationDialog: true })}
                                >
                                    Add Employee
                                </Button>
                                <Button variant="outlined" color="primary" onClick={() => this.prev6months()} style={{ marginLeft: "10px" }}>
                                    prev 6 months
                                </Button>
                                <Button variant="outlined" color="primary" onClick={() => this.next6months()} style={{ marginLeft: "10px" }}>
                                    next 6 months
                                </Button>
                                {/* </Box> */}
                            </Box> : null}

                        {
                            this.state.isLoading1 ? <LoopCircleLoading color="#005D99" /> :
                                this.state.responseData.length > 0 ?
                                    <>{this.state.splittedRoleEmployees.length > 0 ?
                                        <div style={{ maxHeight: window.innerHeight - 260, overflowY: 'scroll' }}>
                                            {
                                                this.state.splittedRoleEmployees.map((role, roleIndex) => (
                                                    <div>
                                                        <RolesEmployeeLocation
                                                            selectedStartDate={this.state.selectedStartDate}
                                                            selectedEndDate={this.state.selectedEndDate}
                                                            rolesObj={this.state.rolesObj}
                                                            refreshData={this.handleRefresh}
                                                            roleData={this.state.splittedRoleEmployees[roleIndex]}
                                                            projectObj={this.state.projectObj}
                                                            role={this.state.reportedRoles[roleIndex]}
                                                            templateRoles={this.state.responseData}
                                                            allOtherTasks={this.state.allOtherTasks}
                                                            allEmployeesLeaves={this.state.allEmployeesLeaves}
                                                            othertaskObject={this.state.othertaskObject}
                                                            allProjects={this.state.allProjects}
                                                            allRoles={this.state.allRoles}
                                                            liveOpportunities={this.state.liveOpportunities}
                                                            updateTheUpdatedSchedules={this.editedScheduleUIUpdate}
                                                            addAllocation={this.addAllocation}
                                                        />
                                                    </div>))
                                            }
                                        </div> : <Typography style={{ textAlign: 'center' }}>No report available for the selected Options</Typography>}
                                    </>
                                    : this.state.selectedRole.length > 0 && this.state.selectedOwnerRegion.length > 0 && this.state.selectedSubSector.length > 0 && this.state.selectedOwnerDelivery.length > 0 ? (<Typography style={{ textAlign: 'center' }}>{'No employees available for the selected Filters !'}</Typography>) : null
                        }
                        <Dialog
                            fullWidth
                            maxWidth={this.state.allocationDialogSize}
                            open={this.state.allocationDialog}
                            // onClose={handleClose}
                            TransitionComponent={Transition}
                            disableEnforceFocus
                        >
                            <DialogTitle>
                                <Box display={'flex'} flexDirection={'row'} width={'100%'} alignItems={'center'}>
                                    <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                                        {!this.state.allocationData ? "Add Employee" : "Add Resourse to " + this.state.allocationData?.emp?.first_name + " " + this.state.allocationData?.emp?.last_name}
                                    </Typography>
                                    <IconButton onClick={handleClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </DialogTitle>
                            <DialogContent>
                                <UserAllocation
                                    getDialogSize={this.getDialogSize}
                                    closeDialog={this.closeDialog}
                                    allocationData={this.state.allocationData} />
                            </DialogContent>
                        </Dialog>
                        <Dialog open={this.state.excelExportDialog} onClose={() => this.setState({ excelExportDialog: false })} >
                            <DialogContentText>
                                <Box margin={2}>
                                    The Report will be emailed to {user.email}
                                </Box>
                            </DialogContentText>
                            <DialogActions>
                                <Button onClick={() => this.setState({ excelExportDialog: false })} color="primary">
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Box>
                }
            </div >)
    }
}

export default withSnackbar(ResourceLocation)