import React from "react";
import MaterialTable from "material-table";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Service from "../../config/networkutils";
import { withStyles } from '@material-ui/core/styles';
import PRPTemplate from '../PRPTemplate/PRPTemplate';
import { sales_status_api, gate_stage_api } from '../../config/apiList';
import { withSnackbar } from 'notistack';
import { getCookie } from '../../utils/CookieHelper';
import { RotateCircleLoading } from 'react-loadingg';
import { getLookupObj } from "../../utils/reuseFunctions";
import { Box, Paper, Tab, Tabs } from "@material-ui/core";
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import PropTypes from 'prop-types';

const token = getCookie("ptd_ts_token");
const user = JSON.parse(getCookie("ptd_ts_user"))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = (theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%'
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
});


const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    // let _componentStatus.current = useRef(true);
    // useEffect(() => {
    //     let componentMounted = true;
    //       const fetchData = async () => {
    //        //you async action is here
    //         if(componentMounted) {
    //           setData(response?.data);
    //         }
    //       };
    //       fetchData();
    //       return () => {
    //        componentMounted = false;
    //       }
    //     }, []);
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-prevent-tabpanel-${index}`}
            aria-labelledby={`scrollable-prevent-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'} variant={'body2'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


class SecuredProjects extends React.Component {
    constructor() {
        super()
        this.state = {
            data: [],
            modelOpen: false,
            selectedOpportunity: {},
            reportModal: false,
            completedOpportunities: [],
            view_type: 'Live',
            gateLevelObj: {},
            salesStatusObj: {},
            isLoading: true
        }
    }

    getSalesStatusList() {
        return new Promise((resolve, reject) => {
            Service.get(sales_status_api, {
                headers: {
                    Authorization: "Token " + token,
                },
            }).then(res => resolve(res.data)).catch(error => reject(error))
        })
    }
    handleTabChange(event, newValue) {

        if (newValue === 0) {
            this.setState({ tabValue: newValue })
        }
        if (newValue === 1) {
            this.setState({ tabValue: newValue })
        }
    };
    getGateLevelList() {
        return new Promise((resolve, reject) => {
            Service.get(gate_stage_api, {
                headers: {
                    Authorization: "Token " + token,
                },
            }).then(res => resolve(res.data)).catch(error => reject(error))
        })
    }

    getData() {
        Promise.all([
            this.getGateLevelList(),
            this.getSalesStatusList()
        ]).then(
            res => {
                let newArr = []
                for (let i = 0; i < res.length; i++) {
                    let formattedObj = getLookupObj(res[i]);
                    newArr.push(formattedObj)
                }
                this.setState({
                    gateLevelObj: newArr[0],
                    salesStatusObj: newArr[1],
                })

                this.setColumn()
            })
            .catch(e => {
                this.setState({ isLoading: false })
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    componentDidMount() {
        if (this.props) {
            this.setState({
                tabValue: this.props.tabValue
            })
        }
        this.setState({ isLoading: true })
        this.getData()
    }

    setColumn() {
        let columns = [
            // {
            //     title: 'Favourite', field: 'is_favourite',
            //     render: rowData => <div>
            //         {
            //             <IconButton onClick={(event) => event.preventDefault()}>
            //                {rowData.is_favourite ? <StarIcon color="secondary" /> : <StarBorderIcon color="secondary" />}
            //             </IconButton> 
            //         }
            //     </div>
            // },
            { title: 'Opportunity Id', field: 'opportunity_id', },
            { title: 'Opportunity Name', field: 'name', },
            {
                title: "Opportunity Status",
                field: "archive_reason",
                lookup: {
                    0: "live",
                    1: "Lost",
                    2: "Withdrawn",
                    3: "Client cancelled",
                    4: "Complete",
                },
            },
            {
                title: 'Project Color', field: 'color_code', width: 100, render: (rowData) => <div style={{
                    background: rowData.color_code ? rowData.color_code : '', width: '3rem', height: '1rem'
                }}></div>,
            },
            { title: 'Location', field: 'location', },
            { title: 'Contract Start Date', field: 'contractor_start_date', type: 'date', },
            { title: 'Contract End Date', field: 'contractor_end_date', type: 'date', },
            { title: 'Gate Level', field: 'gate_level_acheived', lookup: this.state.gateLevelObj },
            { title: 'Sales Status', field: 'sales_status', lookup: this.state.salesStatusObj },
        ]
        this.setState({ columns: columns }, () => this.fetchOps())
    }

    fetchOps() {
        Service.get('/api/crm/user/pref/crm_user_favourite_project/?stage=5&is_trashed=False', {
            headers: {
                Authorization: "Token " + token,
            }
        })
            .then(res => {
                setTimeout(() => {
                    // let filtered = res.data.filter(ele => ele.bid_resource)
                    // let completedOpportunitis = []; let live = [];
                    // filtered.forEach(opportunity => {
                    //     if (opportunity.archive_reason > 0) {
                    //         completedOpportunitis.push(opportunity);
                    //     }
                    //     else live.push(opportunity)
                    // })


                    this.setState({ data: res.data.live, completedOpportunities: res.data.completed, }, () => {
                        let searchParams = new URLSearchParams(window.location.search)
                        // let searchParams;
                        let project;
                        // let lastUri = sessionStorage.getItem('lasturi');
                        // if (lastUri) {
                        //     searchParams = new URLSearchParams(lastUri.split("?")[1])
                        project = searchParams.get("proj")
                        // }

                        let allOpportunities = [...this.state.data, ...this.state.completedOpportunities]
                        // let params = new URLSearchParams(window.location.href.split("?")[1])
                        // let project = parseInt(params.get("proj"))
                        if (project) {
                            let selectedOpportuniti = allOpportunities.filter((opportunity) => opportunity.id === parseInt(project))
                            if (selectedOpportuniti.length > 0) {
                                // let searchParams = new URLSearchParams(window.location.search)

                                // searchParams.set("proj", parseInt(project));

                                // let hash = window.location.hash

                                // let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

                                // window.history.pushState(null, '', newRelativePathQuery);
                                // sessionStorage.setItem("lasturi", `${window.location.protocol}//${window.location.host}/#Dashboard?comp=Secured&proj=${parseInt(comp)}`)
                                // window.history.pushState({ id: window.location.href }, '', `${window.location.protocol}//${window.location.host}/#Dashboard?comp=Secured&proj=${parseInt(comp)}`)
                                this.setState({ selectedOpportunity: selectedOpportuniti[0], modelOpen: true })
                            } else {
                                // let searchParams = new URLSearchParams(window.location.search)

                                // searchParams.delete("proj");

                                // let hash = window.location.hash

                                // let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

                                // window.history.pushState(null, '', newRelativePathQuery);
                                // sessionStorage.setItem("lasturi", `${window.location.protocol}//${window.location.host}/#Dashboard?comp=Secured`)
                                // window.history.pushState({ id: window.location.href }, '', `${window.location.protocol}//${window.location.host}/#Dashboard?comp=Secured`)
                            }
                        } else {
                            // let searchParams = new URLSearchParams(window.location.search)

                            searchParams.delete("proj");

                            let hash = window.location.hash

                            let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

                            window.history.pushState(null, '', newRelativePathQuery);
                            // sessionStorage.setItem("lasturi", `${window.location.protocol}//${window.location.host}/#Dashboard?comp=Secured`)
                            // window.history.pushState({ id: window.location.href }, '', `${window.location.protocol}//${window.location.host}/#Dashboard?comp=Secured`)
                        }

                    })
                }, 1000);
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
            .finally(e => {
                setTimeout(() => {
                    this.setState({ isLoading: false })
                }, 1000);
            })
    }

    rowClicked = (e, rowdata) => {

        // window.history.pushState({ id: window.location.href }, '', `${window.location.protocol}//${window.location.host}/#Dashboard?comp=Secured&proj=${rowdata.id}`)
        // sessionStorage.setItem("lasturi", `${window.location.protocol}//${window.location.host}/#Dashboard?comp=Secured&proj=${rowdata.id}`)
        let searchParams = new URLSearchParams(window.location.search)

        searchParams.set("proj", rowdata.id);

        let hash = window.location.hash
        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

        window.history.pushState(null, '', newRelativePathQuery);
        this.setState({ selectedOpportunity: rowdata })
        this.handleClickOpen()
    }

    handleClickOpen = () => { this.setState({ modelOpen: true }) }


    handleClose = () => {
        // window.history.pushState({ id: window.location.href }, '', `${window.location.protocol}//${window.location.host}/#Dashboard?comp=Secured`)
        // sessionStorage.setItem("lasturi", `${window.location.protocol}//${window.location.host}/#Dashboard?comp=Secured`)
        let searchParams = new URLSearchParams(window.location.search)

        searchParams.delete("proj");

        let hash = window.location.hash
        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;


        window.history.pushState(null, '', newRelativePathQuery);
        this.setState({ modelOpen: false })
    }


    handleChangeMultiple = (event) => {
        const { options } = event.target;
        const value = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        this.setState({ selectedFilter: value });
    };

    handleChange = (event) => {
        this.setState({ selectedFilter: event.target.value });
    };

    handleViewType(e) {
        this.setState({ view_type: e.target.value })
    }

    refreshData(responseData, rowData){
        let dataToChange = this.state.data
        for(let index = 0; index < dataToChange.length; index++) {
           const element =  dataToChange[index];
           if(element.id === rowData.id){
               element.is_favourite = responseData.is_favourite
               break
           }
       }
      

       dataToChange.sort(function (a,b) { return b.is_favourite - a.is_favourite})
       this.setState({
           data: dataToChange,
           isLoading: true
       },() =>{
        this.setState({
            isLoading: false
        })
       })
    }

    favourite(rowData) {
        if ('crm_user_pref_id' in rowData) {
            let obj = {
                "crm": rowData.id,
                "ptd_employee": user.id,
                "is_favourite": !rowData.is_favourite,
                "created_by": user.id
            }
            Service.put(`/api/crm/user/pref/${rowData.crm_user_pref_id}/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: obj,
            })
                .then(res => {
                    this.refreshData(res.data,rowData)
                })
                .catch(error => {
                    this.setState({ isLoading: false })
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                })
        } else {
            let obj = {
                "crm": rowData.id,
                "ptd_employee": user.id,
                "is_favourite": !rowData.is_favourite,
                "created_by": user.id
            }
            Service.post(`/api/crm/user/pref/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: obj,
            })
                .then(res => {
                    this.refreshData(res.data,rowData)
                })
                .catch(error => {
                    this.setState({ isLoading: false })
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                })

        }
    }

    render() {
        const access = this.props.access.access
        return (<>
            {this.state.isLoading ? <RotateCircleLoading color="#005D99" /> : <div>
                <Paper square>
                    {/* {!this.props.disableTab ? */}
                        <Tabs
                            value={this.state.tabValue}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={(e, newValue) => this.handleTabChange(e, newValue)}
                            aria-label="Role Tabs"
                            centered
                        >
                            <Tab label="Live" />
                            <Tab label="Completed" />
                        </Tabs>
                        {/* : null} */}
                    <TabPanel value={this.state.tabValue} index={0}>

                        <MaterialTable
                            title={<h2>Secured Opportunity</h2>}
                            columns={this.state.columns}
                            data={this.state.data}
                            isLoading={this.state.isLoading}
                            options={{
                                paging: false,
                                pageSize: 20,
                                pageSizeOptions: [10, 20, 50, 100],
                                // doubleHorizontalScroll: true,
                                // maxBodyHeight: window.screen.height - 250,
                                headerStyle: {
                                    fontWeight: 'bold',
                                    // color: '#005D99',
                                },
                                filtering: true,
                                padding: 'dense'
                            }}
                            onRowClick={(e, rowData) => { this.rowClicked(e, rowData) }}
                            actions={[
                                rowData => ({
                                    icon: () => rowData.is_favourite ? <StarIcon color="secondary" /> : <StarBorderIcon color="secondary" />,
                                    tooltip: rowData.is_favourite ? "Select to Remove Favourite" : "Select to Add Favourite",
                                    onClick: (event, rowData) => this.favourite(rowData)
                                })
                            ]}
                        /> </TabPanel>

                    <TabPanel value={this.state.tabValue} index={1}>

                        <MaterialTable
                            title={<h2>Completed</h2>}
                            columns={this.state.columns}
                            data={this.state.completedOpportunities}
                            isLoading={this.state.isLoading}
                            options={{
                                paging: false,
                                pageSize: 20,
                                pageSizeOptions: [10, 20, 50, 100],
                                // doubleHorizontalScroll: true,
                                // maxBodyHeight: window.screen.height - 250,
                                headerStyle: {
                                    fontWeight: 'bold',
                                    // color: '#005D99',
                                },
                                filtering: true,
                                padding: 'dense'
                            }}
                            onRowClick={(e, rowData) => { this.rowClicked(e, rowData) }}
                            actions={[
                                rowData => ({
                                    icon: () => rowData.is_favourite ? <StarIcon color="secondary" /> : <StarBorderIcon color="secondary" />,
                                    tooltip: rowData.is_favourite ? "Select to Remove Favourite" : "Select to Add Favourite",
                                    onClick: (event, rowData) => this.favourite(rowData)
                                })
                            ]}
                        />
                    </TabPanel></Paper>

                <div>
                    <Dialog fullScreen open={this.state.modelOpen} onClose={this.handleClose} TransitionComponent={Transition}>
                        <AppBar className={this.props.classes.appBar}>
                            <Toolbar>
                                <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant="h6" className={this.props.classes.title}>

                                    {this.state.selectedOpportunity.name}
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <div style={{ padding: '15px' }}>
                            <PRPTemplate
                                project={this.state.selectedOpportunity}
                                isLoading={this.props.isLoading}
                                addPRPAccess={access.l6a && access.l6a.l6a_b}
                                editPRPAccess={access.l6a && access.l6a.l6a_c}
                                uploadPRPAccess={access.l6a && access.l6a.l6a_c}
                                deletePRPAccess={access.l6a}
                            />
                        </div>
                    </Dialog>
                </div>
            </div>
             }
        </>)
    }
}

export default withSnackbar(withStyles(useStyles)(SecuredProjects))