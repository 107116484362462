import React from 'react';
import { withSnackbar } from "notistack";
import MaterialTable from 'material-table';
import { generalforemanGang, roles_crm_emp } from '../../../../config/apiList';
import Service from '../../../../config/networkutils';
import { getCookie } from '../../../../utils/CookieHelper';
import { Dialog, DialogContent, DialogTitle, Typography, AccordionDetails, Accordion, AccordionSummary, Grid, Box, } from '@material-ui/core';
// import { Close, } from '@material-ui/icons';
import { LoopCircleLoading } from 'react-loadingg';
import { withStyles } from '@material-ui/core/styles';
import { getGeneralforemanGang } from '../../../../config/PromisesHandler';
import moment from 'moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getfilteredByAnotherArrayNotContains, getUniqueItemsArray, groupUniqueObjectsFromArray } from '../../../../utils/reuseFunctions';
import OHLPRPTemplate from './OHLPRPTemplate';

const useStyle = (theme) => ({
    leftPadding: {
        // backgroundColor: "#D5E1E5",
        // minWidth: '100%'
        paddingLeft: '2rem',
        marginBottom: "1rem",
        marginTop: "1rem"
    },
    gangEnd: {
        borderBottom: "3px solid #EDFAFF",
    },
    // rowgap: {
    // }
})

const token = getCookie("ptd_ts_token");
class OHLGang extends React.Component {
    state = {
        gang: [],
        showGangAdd: false,
        isLoading: true,
        gangmenIds: [],
        selectedGangMembers: [],
        gfProjects: [],
        gfgangPRP: [],
        standByGangs: [],
        gangMemberLoading: true,
        standbyGangLoading: true
    }
    filterStandByEmps = (crmArray) => {
        // if (!fromDate && !toDate) {
        //     this.props.enqueueSnackbar("Please Select date range!", {
        //         variant: 'error'
        //     })
        //     return
        // }
        let fromDate = moment().subtract(1, 'M')
        let toDate = moment().add(6, 'M')
        let notScheduledCRMemp = [];
        let scheduledCRMemp = [];

        crmArray.forEach(crm => {
            let scheduled = false
            if (crm.shdl_data) {
                if (crm.shdl_data?.length > 0) {
                    for (let shdlIndex = 0; shdlIndex < crm.shdl_data.length; shdlIndex++) {
                        const shdl = crm.shdl_data[shdlIndex];
                        if (moment(shdl.from.slice(0, 10)).isBetween(moment(fromDate), moment(toDate), undefined, []) && moment(shdl.to.slice(0, 10)).isBetween(moment(fromDate), moment(toDate), undefined, [])) {
                            scheduled = true;
                            break;
                        }
                    }
                }
            }
            if (scheduled) { scheduledCRMemp.push(crm.emp_id) } else { notScheduledCRMemp.push(crm.emp_id) }
        })
        let newGangMen = getfilteredByAnotherArrayNotContains(scheduledCRMemp, this.state.gangmenIds)
        let assignEmployees = this.state.gang.filter(empl => {
            let notBusy = false
            for (let empIndex = 0; empIndex < newGangMen.length; empIndex++) {
                const empId = newGangMen[empIndex];
                if (empId === empl.ptd_employee) {
                    notBusy = true
                    break
                }
            }
            if (notBusy) {
                return true
            } else {
                return false

            }

        })
        return assignEmployees
    }

    filterscheduledEmpsWithInputDate = (fromDate, toDate) => {
        if (!fromDate && !toDate) {
            this.props.enqueueSnackbar("Please Select date range!", {
                variant: 'error'
            })
            return
        }

        let notScheduledCRMemp = [];
        let scheduledCRMemp = [];

        this.state.gfgangPRP.forEach(crm => {
            let scheduled = false

            if (crm.shdl_data && crm.shdl_data.length > 0) {
                for (let shdlIndex = 0; shdlIndex < crm.shdl_data.length; shdlIndex++) {
                    const shdl = crm.shdl_data[shdlIndex];
                    if (moment(shdl.from.slice(0, 10)).isBetween(moment(fromDate), moment(toDate), undefined, []) && moment(shdl.to.slice(0, 10)).isBetween(moment(fromDate), moment(toDate), undefined, [])) {
                        scheduled = true;
                        break;
                    }
                }
            }
            if (scheduled) { scheduledCRMemp.push(crm.emp_id) } else { notScheduledCRMemp.push(crm.emp_id) }
        })
        let newGangMen = getfilteredByAnotherArrayNotContains(scheduledCRMemp, this.state.gangmenIds)
        let assignEmployees = this.state.gang.filter(empl => {
            let notBusy = false
            for (let empIndex = 0; empIndex < newGangMen.length; empIndex++) {
                const empId = newGangMen[empIndex];
                if (empId === empl.ptd_employee) {
                    notBusy = true
                    break
                }
            }
            if (notBusy) {
                return true
            } else {
                return false

            }

        })
        this.setState({ assignEmployees })
    }

    filterscheduledEmps = (crmArray) => {
        let notScheduledCRMemp = [];
        let scheduledCRMemp = [];

        crmArray.forEach(crm => {
            let scheduled = false
            if (crm.shdl_data.length > 0) {
                for (let shdlIndex = 0; shdlIndex < crm.shdl_data.length; shdlIndex++) {
                    const shdl = crm.shdl_data[shdlIndex];
                    if (moment(shdl.to.slice(0, 10)).isAfter(moment())) {
                        scheduled = true;
                        break;
                    }
                }
            }
            if (scheduled) { scheduledCRMemp.push(crm.emp_id) } else { notScheduledCRMemp.push(crm.emp_id) }
        })
        return scheduledCRMemp
    }

    filterBycrm(gfgangPRP, uniqueProject) {
        let crmFiltered = gfgangPRP.filter(prp => prp.crm === uniqueProject.crm)

        let empGrouped = { ...groupUniqueObjectsFromArray(crmFiltered), crm: uniqueProject }
        return empGrouped

    }

    getGFPRP() {
        return new Promise((resolve, reject) => {

            Service.get(roles_crm_emp + `?is_archive=0&emp=${this.props.gf.ptd_employee}`, {
                headers: {
                    Authorization: "Token " + token,
                },
            }).then(res => {
                let uniqueCRM = getUniqueItemsArray(res.data, 'crm');
                resolve(uniqueCRM)
            }
            ).catch(error => {
                reject(error)
            }
            )
        })
    }

    getGangPRP = (gangmenIds, uniqueCRMpRP) => {
        Service.get(roles_crm_emp + `?is_archive=0&emp=${gangmenIds.join()}`, {
            headers: {
                Authorization: "Token " + token,
            },
        }).then(res => {

            let standByGangs = this.filterStandByEmps(res.data)
            let uniqueCRMpRPArray = []
            uniqueCRMpRP.forEach(prp => {
                uniqueCRMpRPArray.push(this.filterBycrm(res.data, prp))

            })
            this.setState({ standByGangs, assignEmployees: [], gfgangPRP: res.data, uniqueCRMpRPArray, standbyGangLoading: false })
        }
        ).catch(error => {
            console.log(error)
            this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' })
        }
        )
    }

    componentDidMount() {
        Promise.all([getGeneralforemanGang(), this.getGFPRP()])
            .then(res => {
                let gangmen = res[0].filter(gm => gm.general_foreman === this.props.gf.id)
                let gangmenIds = [0]
                gangmen.map(man => gangmenIds.push(man.ptd_employee))
                this.getGangPRP(gangmenIds, res[1])
                this.setState({ gang: gangmen, gangmenIds, },)

                let unAllocatedGngmn = [...this.props.allOHLEmp]

                if (res[0].length > 0) {
                    for (let gfEmpIndex = 0; gfEmpIndex < res[0].length; gfEmpIndex++) {
                        let filteredGfNotAllocatedGmn = unAllocatedGngmn.filter(emp => emp.id !== res[0][gfEmpIndex].ptd_employee && (moment(emp.end_date).isAfter(moment().format()) || emp.end_date === null))
                        unAllocatedGngmn = [...filteredGfNotAllocatedGmn]
                    }
                } else {
                    unAllocatedGngmn = this.props.allOHLEmp;
                }
                this.setState({ gang: gangmen, unAllocatedGngmn, isLoading: false, gangMemberLoading: false })
            })
            .catch(error => {
                this.setState({ isLoading: false }, () => this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                }))
            } )
    }

    postOps(data) {
        Service.post(generalforemanGang, {
            headers: {
                Authorization: "Token " + token,
            },
            data: data,
        })
            .then(res => {
                let updatedGang = [...this.state.gang]
                let unAllocatedGngmn = [...this.state.unAllocatedGngmn]
                let removedtheadded = unAllocatedGngmn.filter(emp => moment(emp.end_date).isAfter(moment().format()) || emp.end_date === null)
                updatedGang.push(res.data)
                this.setState({ gang: updatedGang, unAllocatedGngmn: removedtheadded, showGangAdd: false }, () => {
                    this.props.enqueueSnackbar('Client added successfully', {
                        variant: 'success'
                    })
                })
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    deleteOps(data) {
        Service.delete(generalforemanGang + data.id, {
            headers: {
                Authorization: "Token " + token,
            }
        }).then(res => {
            let gangg = [...this.state.gang]
            let dupOhl = [...this.props.allOHLEmp]
            dupOhl = dupOhl.filter(emp => emp.id === data.ptd_employee)
            let dupUnAllocatedGngmn = [dupOhl[0], ...this.state.unAllocatedGngmn]
            gangg = gangg.filter(gman => gman.id !== data.id)
            this.props.enqueueSnackbar('Gang member Deleted !', { variant: 'success' })
            this.setState({ gang: gangg, unAllocatedGngmn: dupUnAllocatedGngmn }, () => {
                this.componentDidMount()
            })
        }).catch(error => {
            this.props.enqueueSnackbar('Deleting Gang member failed !', { variant: 'error' })
        })
    }

    render() {
        return (
            <div>
                {
                    this.state.isLoading ? <LoopCircleLoading color='#005D99' /> :
                        <div>
                            {this.props.option === 0 ?
                                <MaterialTable
                                    title={'Gang Members'}
                                    columns={[{ title: 'Name', field: 'ptd_employee', lookup: this.props.ohlEmpObj },
                                    { title: 'Role', field: 'ptd_employee_data.job_title', lookup: this.props.rolesObj },
                                    ]}
                                    data={this.state.gang}
                                    isLoading={this.state.gangMemberLoading}
                                    style={{ width: '100%' }}
                                    options={{
                                        search: true,
                                        paging: false,
                                        headerStyle: {
                                            fontWeight: 'bold'
                                        },
                                    }}
                                    actions={[
                                        {
                                            icon: 'add',
                                            tooltip: 'Add Gang member',
                                            isFreeAction: true,
                                            onClick: () => { this.setState({ showGangAdd: true }) }
                                        }
                                    ]}
                                    editable={{
                                        onRowDelete: oldData =>
                                            new Promise((resolve, reject) => {
                                                setTimeout(() => {
                                                    this.deleteOps(oldData)
                                                    resolve()
                                                }, 1000)
                                            }),
                                    }}
                                />
                                : null}

                            {this.props.option === 1 ?
                                <MaterialTable
                                    title={'StandBy Gang'}
                                    columns={[{ title: 'Name', field: 'ptd_employee', lookup: this.props.ohlEmpObj },
                                    { title: 'Role', field: 'ptd_employee_data.job_title', lookup: this.props.rolesObj },
                                    ]}
                                    data={this.state.standByGangs}
                                    isLoading={this.state.standbyGangLoading}
                                    style={{ width: '100%' }}
                                    options={{
                                        search: true,
                                        paging: false,
                                        headerStyle: {
                                            fontWeight: 'bold'
                                        },
                                    }}
                                />
                                : null}

                            {this.props.option === 2 ?
                                <Box>
                                    {this.state.uniqueCRMpRPArray?.map((project, projectIndex) => (
                                        <Accordion key={projectIndex}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                            >
                                                <Grid container>
                                                    <Grid item xs={8}>
                                                        <Typography variant='h6'>{project['crm']['crm_name']}</Typography>
                                                    </Grid >

                                                </Grid>

                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <OHLPRPTemplate
                                                    gang={this.state.gang}
                                                    project={project}
                                                    isLoading={this.props.isLoading}
                                                    allOwner_delivery_unitsLupObj={this.props.owner_delivery_unit}
                                                    allOwner_regionsLupObj={this.props.owner_region}
                                                    allSubSectors={this.props.allSectors}
                                                    allSubSectorsLupObj={this.props.allSectorLupObj}
                                                    allOHLEmpsLupObj={this.props.ohlEmpObj}
                                                    allOHLEmps={this.props.allOHLEmp}
                                                    allActiveRoles={this.props.allActiveRoles}
                                                    allActiveRolesLupObj={this.props.rolesObj}
                                                    getActiveRoles={this.props.getActiveRoles}
                                                    allCRMS={this.props.allCRMS}
                                                    assignEmployees={this.state.assignEmployees}
                                                    filterscheduledEmpsWithInputDate={this.filterscheduledEmpsWithInputDate}
                                                    assignEmployeesUpdate={(arrayy) => this.setState({ assignEmployees: arrayy })}
                                                />
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}
                                </Box>
                                : null}

                            <Dialog open={this.state.showGangAdd} onClose={() => { this.setState({ showGangAdd: false }) }} maxWidth={"lg"}>
                                <DialogTitle>
                                    <Typography>Add Gang Member</Typography>
                                </DialogTitle>
                                <DialogContent style={{ padding: 0 }}>
                                    <MaterialTable
                                        title="Employees"
                                        columns={[
                                            { title: 'Employee No', field: 'emp_no', },
                                            { title: 'First Name', field: 'first_name', },
                                            { title: 'Last Name', field: 'last_name', },
                                            { title: 'Email Address', field: 'email', },
                                            { title: 'Role', field: 'job_title', lookup: this.props.rolesObj },
                                            { title: 'Owner Region', field: 'owner_region', lookup: this.props.owner_region },
                                            { title: 'Owner Delivery Unit', field: 'owner_delivery_unit', lookup: this.props.owner_delivery_unit },
                                            { title: 'Sector', field: 'sector', lookup: this.props.sector },
                                            { title: 'Start Date', field: 'start_date', type: 'date' },
                                            { title: 'End Date', field: 'end_date', type: 'date' },
                                        ]}
                                        data={this.state.unAllocatedGngmn}
                                        isLoading={this.state.isLoading}
                                        options={{
                                            headerStyle: {
                                                fontWeight: 'bold',
                                            },
                                            paging: false,
                                            padding: 'dense',
                                            showTitle: false,
                                            searchFieldAlignment: 'left',
                                            pageSize: 20
                                        }}
                                        onRowClick={(event, rowData, togglePanel) => {
                                            this.postOps({
                                                ptd_employee: rowData.id,
                                                general_foreman: this.props.gf.id,
                                            })
                                        }}
                                    />
                                </DialogContent>
                            </Dialog>
                        </div>
                }</div>
        );
    }
}

export default withSnackbar(withStyles(useStyle)(OHLGang));