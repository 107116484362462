import React, { Component } from 'react';
import Service from '../../config/networkutils';
import { getCookie } from '../../utils/CookieHelper';
import { getEmployees } from '../../config/PromisesHandler';
import { LoopCircleLoading } from 'react-loadingg';
import { getPrimaryColor } from '../Helper/CommonThings';
import { AppBar, Box, Button, Dialog, DialogContent, IconButton, Toolbar, Tooltip, Typography, } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withSnackbar } from 'notistack';
import UnAssignedSchedules from './UnAssignedSchedules';
import AllocateSchedules from './AllocateSchedules';
import moment from 'moment';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import CheckAvailability from '../CheckAvailability/CheckAvailability';
import CloseIcon from '@material-ui/icons/Close';

const token = getCookie("ptd_ts_token");
class UserAllocation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            crm: [],
            selectedUser: null,
            selectedCrm: null,
            isLoading: true,
            schedules: [],
            buttonDisabled: false,
            mainMenu: true,
            open: false,
        }
        this.getMenu = this.getMenu.bind(this)
        this.closeDialog = this.closeDialog.bind(this)
        this.refreshData = this.refreshData.bind(this)
        this.handleSelectedUser = this.handleSelectedUser.bind(this)
    }

    componentDidMount() {
        if (this.props.allocationData?.emp && this.props.allocationData?.prj) {
            this.setState({
                isLoading: true,
                selectedUser: {
                    id: this.props.allocationData.emp.employee_id,
                    first_name: this.props.allocationData.emp.first_name,
                    last_name: this.props.allocationData.emp.last_name,
                    role_name: this.props.allocationData.emp.role_name
                },
                selectedCrm: {
                    crm_id: this.props.allocationData.prj.crm,
                    crm_name: this.props.allocationData.prj.crm_name
                }
            }, () => {
                this.fetchSchedule()
            })
        } else {
            Promise.all([
                getEmployees(),
                // getCRM(),
            ])
                .then(res => {
                    this.setState({
                        users: res[0].filter(emp => (!emp.end_date ? true : moment(emp.end_date).isAfter(moment().format()))),
                        isLoading: false
                    }, () => {
                        if (this.props.allocationData?.emp) {
                            let user = this.state.users.find(user => user.id === this.props.allocationData.emp.employee_id)
                            this.setState({ selectedUser: user }, () => {
                                if (this.state.selectedUser) {
                                    this.getCRM()
                                }
                            })
                        }
                    })
                })
                .catch(e => {
                    this.props.enqueueSnackbar('Something went wrong!.', {
                        variant: 'error'
                    });
                })
        }
    }

    getCRM() {
        Service.get(`/api/crm/get_crm_for_role/?user_id=${this.state.selectedUser.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    crm: res.data
                })
                if (res.data.length === 0) {
                    this.props.enqueueSnackbar('No CRM found for this user.', {
                        variant: 'warning'
                    });
                }
            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            });
    }

    fetchSchedule = () => {
        var fields = ""
        if (!this.state.selectedCrm) {
            fields = "Crm"
        }
        if (!this.state.selectedUser) {
            fields += !fields ? "User" : ", User"
        }
        if (fields) {
            this.props.enqueueSnackbar(fields + ' is required.', {
                variant: 'warning'
            });
        } else {
            this.setState({ buttonDisabled: true })
            Service.get(`/api/prp/rolenew/get_unassigned_shdl/?crm=${this.state.selectedCrm?.crm_id}&emp=${this.state.selectedUser.id}`, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    let data = res.data
                    for (let i = 0; i < data.length; i++) {
                        const element = data[i];
                        element.isSelected = false
                        for (let j = 0; j < element.shdl_data.length; j++) {
                            const element1 = element.shdl_data[j];
                            element1.isSelected = false
                            element1.updated_qty = element1.qty
                        }
                    }
                    this.setState({
                        schedules: data.sort((a, b) => b.shdl_data.length - a.shdl_data.length),
                        buttonDisabled: false,
                        mainMenu: false,
                        isLoading: false
                    }, () => {
                        if ((this.state.schedules.length > 0) && (this.props.getDialogSize)) {
                            this.props.getDialogSize('xl')
                        }
                    })
                })
                .catch(error => {
                    console.log(error)
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                    this.setState({ buttonDisabled: false })
                });
        }
    }

    handleCrmChange = (event, values) => {
        this.setState({ selectedCrm: values })
    }

    handleUserChange = (event, values) => {
        if (values) {
            this.setState({ selectedUser: values, selectedCrm: null, crm: [] }, () => {
                this.getCRM()
            })
        } else {
            this.setState({ selectedUser: values, selectedCrm: null, crm: [] })
        }
    }

    getMenu(type) {
        if (type === 'main-menu') {
            this.setState({ mainMenu: true, }, () => {
                if (this.props.getDialogSize) {
                    this.props.getDialogSize('xs')
                }
            })
        } else {
            this.setState({ mainMenu: false, schedules: [] }, () => {
                if (this.props.getDialogSize) {
                    this.props.getDialogSize('xs')
                }
            })
        }
    }

    closeDialog = () => {
        if (this.props.closeDialog) {
            this.props.closeDialog()
        }
    }

    refreshData = () => {
        this.setState({ isLoading: true }, () => {
            this.fetchSchedule()
        })
    }

    handleSelectedUser = (e, user) => {
        this.setState({ open: false })
        let selectedUser = this.state.users.find(u => u.id === user.id)
        if (selectedUser) {
            this.handleUserChange(e, selectedUser)
        } else {
            this.props.enqueueSnackbar('The User does not Exist', {
                variant: 'warning'
            });
        }
    }

    render() {

        return (
            <div>
                {
                    this.state.isLoading ? <LoopCircleLoading color={getPrimaryColor()} /> :
                        <div>
                            {this.state.mainMenu ?
                                <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} width={'100%'} alignItems={'center'}>
                                    {
                                        !this.props.allocationData?.emp ?
                                            <Box width={"100%"} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                <Autocomplete
                                                    id={"user"}
                                                    fullWidth
                                                    value={this.state.selectedUser}
                                                    options={this.state.users}
                                                    style={{ marginBottom: "10px" }}
                                                    getOptionLabel={(option) => option.last_name + ', ' + option.first_name + ' (' + option.role_name + ')'}
                                                    onChange={(e, v) => this.handleUserChange(e, v)}
                                                    renderInput={(params) => <TextField {...params} label="Select User" variant="outlined" />}
                                                /><Tooltip title="Check Availability"><IconButton style={{ marginLeft: '5px' }} onClick={() => this.setState({ open: true })}><BeenhereIcon /></IconButton></Tooltip>
                                            </Box> : null}
                                    {
                                        !this.props.allocationData?.prj ?
                                            <Autocomplete
                                                id={"crm"}
                                                fullWidth
                                                value={this.state.selectedCrm}
                                                options={this.state.crm}
                                                getOptionLabel={(option) => option.crm_name}
                                                onChange={(e, v) => this.handleCrmChange(e, v)}
                                                renderInput={(params) => <TextField {...params} label="Select CRM" variant="outlined" />}
                                            /> : null}
                                    <Box style={{ marginTop: "10px" }}>
                                        <Button disabled={this.state.buttonDisabled} variant="contained" color="primary" onClick={() => { this.fetchSchedule() }}>
                                            Allocate
                                        </Button>
                                    </Box>
                                </Box>
                                :
                                (this.state.schedules.length > 0 ?
                                    <UnAssignedSchedules
                                        schedules={this.state.schedules}
                                        getMenu={this.getMenu}
                                        crm={this.state.selectedCrm}
                                        user={this.state.selectedUser}
                                        closeDialog={this.closeDialog}
                                        refreshData={this.refreshData}
                                        allocationData={this.props.allocationData} />
                                    : <AllocateSchedules
                                        getMenu={this.getMenu}
                                        crm={this.state.selectedCrm}
                                        user={this.state.selectedUser}
                                        closeDialog={this.closeDialog}
                                        allocationData={this.props.allocationData} />)
                            }
                            <Dialog fullScreen open={this.state.open} onClose={() => this.setState({ open: false })}>
                                <AppBar >
                                    <Toolbar>
                                        <IconButton edge="start" color="inherit" onClick={() => { this.setState({ open: false }) }} aria-label="close">
                                            <CloseIcon />
                                        </IconButton>
                                        <Typography variant="h6" >
                                            Check Availability
                                        </Typography>
                                    </Toolbar>
                                </AppBar>
                                <DialogContent>
                                    <div style={{ marginTop: '80px' }}>
                                        <CheckAvailability
                                            selectedUser={this.handleSelectedUser}
                                        // startDate={'2021-05-24'}
                                        // endDate={'2022-05-24'}
                                        // role={{id: 314}}
                                        // qty={1}
                                        />
                                    </div>
                                </DialogContent>
                            </Dialog>
                        </div>
                }
            </div>
        );
    }
}

export default withSnackbar(UserAllocation);