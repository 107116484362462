import { Box } from '@material-ui/core';
import React from 'react'
import ReactApexChart from 'react-apexcharts';
import { getActiveRoles, getEmployees, getGeneralforeman, getGeneralforemanGang } from '../../../../config/PromisesHandler';
import Service from '../../../../config/networkutils';
import { roles_crm_emp } from '../../../../config/apiList';
import { getCookie } from '../../../../utils/CookieHelper';
import { getIdFnameLnameObj, getUniqueItemsArray } from '../../../../utils/reuseFunctions';
import { LoopCircleLoading } from 'react-loadingg';

const token = getCookie("ptd_ts_token");

var gfRoleId = null
var ohlroles = null
class OHLDashboard extends React.Component {
    state = {
        allGfs: [], ohlEempObj: {}, ohlempIDs: [], allGangEmps: [], gfs: [], rolesObj: {},
        allActiveRoles: [], gangCRMs: [],
        gfCRMs: [],
        gfSeries: [],
        gfLabels: [],
        roleLabels: [],
        roleSeries: [],
        crmLabels: [],
        crmSeries: [],
        isLoading: true,
        options: {
            chart: {
                width: 400,
                type: 'pie',
            },
            series: [],
            labels: [],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },

    }

    getPRPR(empIDSArray) {
        return new Promise((resolve, reject) => {

            Service.get(roles_crm_emp + `?is_archive=0&emp=${empIDSArray.join()}`, {
                headers: {
                    Authorization: "Token " + token,
                },
            }).then(res => {
                let uniqueCRM = getUniqueItemsArray(res.data, 'crm');
                resolve(uniqueCRM)
            }
            ).catch(error => {
                reject(error)
            }
            )
        })
    }

    componentDidMount() {

        ohlroles = this.props.roles
        gfRoleId = this.props.gfRoleId

        console.log(this.props.gfRoleId)

        Promise.all([getEmployees(), getGeneralforeman(this.props.discipline), getActiveRoles(), getGeneralforemanGang()])
            .then(res => {

                let ohlemps = []
                let ohlempIDs = []
                // Filtering all the OHL Role employees mentioned in the this.ohlroles
                for (let empin = 0; empin < res[0].length; empin++) {
                    ohlroles.forEach(roleId => {
                        if (res[0][empin].job_title === roleId) {
                            ohlemps.push(res[0][empin])
                            ohlempIDs.push(res[0][empin].id)
                        }
                    })
                }
                let rolesObj = {}
                res[2].forEach(ele => {

                    let newData = { [ele.id]: ele.job_roles }
                    rolesObj = { ...rolesObj, ...newData }
                })

                // Grouping by Role
                let groupByOHLRoles = {}
                ohlemps.forEach((emp => {
                    if (groupByOHLRoles.hasOwnProperty(emp.job_title)) {
                        groupByOHLRoles[emp.job_title] = [...groupByOHLRoles[emp.job_title], emp]
                    } else {
                        groupByOHLRoles = { ...groupByOHLRoles, [emp.job_title]: [emp] }
                    }
                }))

                // Making labels and series

                let roleLabels = []
                let roleSeries = []
                Object.keys(groupByOHLRoles).forEach(roleKey => {
                    let roleStrength = groupByOHLRoles[roleKey].length
                    roleLabels.push(rolesObj[roleKey] + ' = ' + roleStrength)
                    roleSeries.push(roleStrength)

                })


                // filtering only generalforemen role
                let allGfIDs = []
                let allGfs = ohlemps.filter(emp => gfRoleId.filter(role => role === emp.job_title).length > 0)
                allGfs.forEach(emp => {
                    allGfIDs.push(emp.id)
                })
                let allGangEmps = ohlemps.filter(emp => emp.job_title !== gfRoleId)


                // grouping GF and his Gang
                let gfAndGang = {}
                for (let gmnIndex = 0; gmnIndex < res[3].length; gmnIndex++) {
                    const gMN = res[3][gmnIndex];
                    if (gfAndGang.hasOwnProperty(gMN.general_foreman)) {
                        gfAndGang[gMN.general_foreman].push(gMN)
                    } else {
                        gfAndGang = { ...gfAndGang, [gMN.general_foreman]: [gMN] }
                    }

                }
                const getEmployeeName = (empId, empArray) => {
                    for (let empIndex = 0; empIndex < empArray.length; empIndex++) {
                        const emp = empArray[empIndex];
                        if (emp.id === empId) {
                            return emp.first_name + ' ' + emp.last_name
                        }

                    }
                }

                let gfLabels = []
                let gfSeries = []
                Object.keys(gfAndGang).forEach(gfKey => {
                    for (let gfObjIndex = 0; gfObjIndex < res[1].length; gfObjIndex++) {
                        const gf = res[1][gfObjIndex];
                        if (gf.id === parseInt(gfKey)) {
                            gfLabels.push(getEmployeeName(gf.ptd_employee, ohlemps) + " = " + gfAndGang[gfKey].length)
                            gfSeries.push(gfAndGang[gfKey].length)
                        }

                    }

                })
                // filtering not Allocated generalforemen


                // getting lookup objects
                let ohlEempObj = getIdFnameLnameObj(ohlemps)

                this.setState({ roleLabels, roleSeries, gfLabels, gfSeries, allGfs, ohlEempObj, ohlempIDs, allGangEmps, gfs: res[1], rolesObj, allActiveRoles: res[2], gfAndGang },
                    () => {
                        Promise.all([
                            this.getPRPR(ohlempIDs),
                            this.getPRPR(allGfIDs)
                        ]).then((res => {
                            let allCrms = [...res[0], ...res[1]]
                            let groupByCrms = {}
                            let crmLookupobj = {}
                            allCrms.forEach((crm => {
                                crmLookupobj = { ...crmLookupobj, [crm.crm]: [crm.crm_name] }
                                if (crm.emp_id) {

                                    if (groupByCrms.hasOwnProperty(crm.crm)) {
                                        if (!groupByCrms[crm.crm].includes(crm.emp_id)) {
                                            groupByCrms[crm.crm] = [...groupByCrms[crm.crm], crm.emp_id]
                                        }
                                    } else {
                                        groupByCrms = { ...groupByCrms, [crm.crm]: [crm.emp_id] }
                                    }

                                }
                            }))

                            let crmLabels = []
                            let crmSeries = []
                            Object.keys(groupByCrms).forEach(crmGrp => {
                                let crmGrpStrength = groupByCrms[crmGrp].length
                                crmLabels.push(crmLookupobj[crmGrp] + ' = ' + crmGrpStrength)
                                crmSeries.push(crmGrpStrength)

                            })

                            this.setState({
                                gangCRMs: res[0],
                                gfCRMs: res[1],
                                crmLabels,
                                crmSeries
                            }, () => {

                                this.setState({
                                    isLoading: false
                                })
                            })
                        }))
                            .catch((ex) => {
                                console.log(ex)
                            })
                    })
            })

            .catch(e => {
                this.setState({ isLoading: false })
            })


    }

    render() {
        return (
            <div>
                {
                    this.state.isLoading ? <LoopCircleLoading color='#005D99' /> :
                    <Box>
                        <Box display='flex' justifyContent={'space-around'} alignItems={'center'}>
                            <Box >
                                <ReactApexChart options={{
                                    ...this.state.options, labels: this.state.gfLabels, title: {
                                        text: 'General Foremen Gang Strength',
                                        align: 'left',
                                        margin: 10,
                                    }
                                }} series={this.state.gfSeries} type="pie" width={580} />
                            </Box>
                            <Box>
                                <ReactApexChart options={{
                                    ...this.state.options, labels: this.state.roleLabels, title: {
                                        text: 'Role Strength',
                                        align: 'left',
                                        margin: 10,
                                    }
                                }} series={this.state.roleSeries} type="pie" width={700} />
                            </Box>
                        </Box>
                            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} margintTop={10}>
                                <ReactApexChart options={{
                                    ...this.state.options, labels: this.state.crmLabels, title: {
                                        text: 'CRM Strength',
                                        align: 'left',
                                        margin: 10,
                                    }
                                }} series={this.state.crmSeries} type="pie" width={1100} />
                            </Box>
                        </Box>
                }
            </div>

        )
    }
}

export default OHLDashboard